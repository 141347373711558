// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import React from "react";
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface File {
  title: string;
  count: number;
  file: SubFile[];
}

interface SubFile {
  fileName: string;
  size: string;
}

interface S {
  projectName: string;
  streetName: string;
  city: string;
  postCode: string;
  country: string;
  state: string;
  expandedIndex: number | null,
  menuAnchor: HTMLElement | null;
  activeFile: SubFile | null;
  currentStatus: string;
  menuItemIndex: number | null;
  token: string;
  uploadedFiles: {
    section_title: string, filesCount: number, mandatory: boolean, section_id: number
    files: { file_name: string, size: string, file_url: string, file_id: number }[]
  }[],
  projectId: string;
  selectedSectionId: number;
  rejectfileid:number | null;
  fileurl:string;
  filename:string;


}

interface SS {
  id: any;
}

export default class SpecialistCostPlanController extends BlockComponent<
  Props,
  S,
  SS
> {
  fileInputRef = React.createRef<HTMLInputElement>();
  getSpecialistProjectCallId: string = '';
  deleteFileCallId: string = '';
  uploadFileCallId: string = '';
  handelStripeCallId:string='';
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      projectName: "Skyline Tower Developments",
      streetName: '123 Riverside Avenue',
      city: 'Cambridge',
      postCode: 'CB1 1AB',
      country: 'Cambridgeshire',
      state: 'United Kingdom',
      expandedIndex: null,
      menuAnchor: null,
      activeFile: null,
      currentStatus: 'Accepted',
      menuItemIndex: null,
      token: '',
      uploadedFiles: [],
      projectId: '',
      selectedSectionId: 0,
      rejectfileid: null,
      fileurl:"",
      filename:"",

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.status === 500) {
          toast.error("Something went wrong!");
        } else if (responseJson.errors) {
          toast.error(responseJson.errors);
        }
      }
    }
    this.receive1(message);
  }
  receive1 = (message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getSpecialistProjectCallId) {
        this.specialistProjectResponse(responseJson);
        
      }
      if (apiRequestCallId == this.deleteFileCallId) {
        if (responseJson.meta.message == 'File deleted successfully') {
          this.getSpecialistProject()
          this.handleMenuClose()
        }
      }
      if (apiRequestCallId == this.uploadFileCallId) {
        if (responseJson.message == "Files uploaded successfully") { this.getSpecialistProject() }
      }
      if(apiRequestCallId==this.handelStripeCallId){
        this.stripeResponse(responseJson)
      }
    }

  }
  specialistProjectResponse = (responseJson: any) => {
    const data = responseJson.data
    this.setState({
      projectName: data.attributes.project_name,
      city: data.attributes.addressable.city,
      streetName: data.attributes.addressable.address,
      postCode: data.attributes.addressable.post_code,
      state: data.attributes.addressable.state,
      country: data.attributes.addressable.country,
      projectId: data.id,
      currentStatus:data.attributes.status
      // currentStatus: 'Rejected'
    })
    const uploadedFiles = data.attributes.uploaded_files?.map((item: any) => (
      {
        section_title: item.section_title,
        filesCount: item.documents.length,
        mandatory: item.mandatory,
        section_id: item.section_id,
        files: item.documents?.map((fileItem: any) => ({
          file_id: fileItem.attachment_id,
          file_name: fileItem.file_name,
          size: fileItem.size,
          file_url: fileItem.url,
        }))

      }
    ))
    this.setState({ uploadedFiles })
  }

  goBack() {
    this.props.navigation.goBack();
  }

  toggleExpand = (index: number) => {
    this.setState((prevState) => ({
      expandedIndex: prevState.expandedIndex === index ? null : index,
    }));
  };
  handleMenuOpen = (event: React.MouseEvent<HTMLDivElement>, index: number,fileID: any) => {
    
    this.setState({
      menuAnchor: event.currentTarget,
      menuItemIndex: index,
      rejectfileid:fileID.file_id,
      fileurl:fileID.file_url      ,
      filename:fileID.file_name
      ,
    });
  };


  handleMenuClose = () => {
    this.setState({ menuAnchor: null, activeFile: null });
  };
  handleFileUploadClick = (section_id: number) => {
    this.setState({ selectedSectionId: section_id })
    if (this.fileInputRef.current) {
      this.fileInputRef.current.click();
    }
  }
  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const fileType = file.type;
      const validTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];
      if (!validTypes.includes(fileType)) {
        alert('Only PNG, JPG, JPEG, and PDF files are allowed.');
      }
      else { this.uploadFiles(file) }
    }
  };
  async componentDidMount() {
    this.getIdFromUrl()
    const token = await getStorageData("token");
    this.setState({ token });
    this.getSpecialistProject()
  }


  getIdFromUrl = () => {
    const path = window.location.pathname;
    const segments = path.split("/"); 
    return segments[2]; 
  };

  getSpecialistProject = () => {
    const actualId =this.getIdFromUrl();
    const endpoint = `bx_block_profile/projects/specialist_cost_plans_details/${actualId}`;

    const header = {
      "Content-Type": configJSON.getProjectPlanListApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSpecialistProjectCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleDownloadfile=()=>{
this.handleDownload(this.state.fileurl,this.state.filename)
  }
  handleDownload = async (fileUrl: any, fileName: string) => {
    try {
      const response = await fetch(fileUrl, {
        method: 'GET',
      });

      if (!response.ok) {
        throw new Error('Failed to fetch the file');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      link.remove();

      window.URL.revokeObjectURL(url);
      this.handleMenuClose()
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  }
  deleteFile = (fileId: number) => {
    
    const id = this.state.projectId;
    const endpoint = `bx_block_profile/projects/${id}/delete_file/${this.state.rejectfileid}`;

    const header = {
      "Content-Type": configJSON.getProjectPlanListApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteFileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'DELETE'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  uploadFiles = (file: any) => {
    const header = {
      token: this.state.token,
    };
    const formData = new FormData();
    formData.append("files[][file]", file);
    formData.append("project_id", this.state.projectId);
    formData.append("files[][section_id]", this.state.selectedSectionId.toString());
    formData.append("files[][file_name]", file.name);
    formData.append("files[][content_type]", file.type);


    const getProfileMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.uploadFileCallId = getProfileMsg.messageId;

    getProfileMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    getProfileMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/projects/upload_files`
    );

    getProfileMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getProfileMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `POST`
    );

    runEngine.sendMessage(getProfileMsg.id, getProfileMsg);
  }
  handleResubmit = async () => {
    let isFieldsFull = true;
  
    this.state.uploadedFiles.forEach((item) => {
      if (item.mandatory && (!item.files || item.files.length === 0)) {
        isFieldsFull = false;
      }
    });
  
    if (!isFieldsFull) {
      return;
    }
  
    const token = await getStorageData("token");
    const plan_id = 3;
    const project_id = this.state.projectId;
  
    const header = {
      token: token,
      "Content-Type": configJSON.validationApiContentType,
    };
  
    const body = {
      plan_id: plan_id,
      files_uploaded: true,
    };
  console.log("+++++++++++");
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.handelStripeCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.stripeAPiEndPoint}/${project_id}/pay_specialist_plan`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.stripeAPiEndMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  stripeResponse=(responseJson:any)=>{
    window.location.href = responseJson.checkout_url;
  }
}

// Customizable Area End
