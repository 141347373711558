// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, isEmail, setStorageData } from "../../../framework/src/Utilities";
import { IImageUrl, IPresignUrl, ImageUrl } from "./types";
import { Country, State } from "country-state-city";
import DocumentPicker, {
  DocumentPickerResponse,
} from "react-native-document-picker";
import { FormikHelpers } from "formik";
import React from "react";
import { styled, Typography } from "@material-ui/core";
import { JpgIcon, PdfIcon, PngIcon } from "./Icons";
import { bedImage, buildNewIcon, mockIcon } from "./assets";
import _ from "lodash"
import { toast } from "react-toastify";
import storage from "../../../framework/src/StorageProvider";


export const configJSON = require("./config");
interface Section {
  attributes: any;
  id: number;
  name: string;
  mandatory: boolean;
}

interface TaskResponse {
  message: string;
  task: {
    id: number;
    count: number | null;
    bx_block_tasks_task_lists_id: number;
    room_id: number;
    project_id: number;
    project_rooms_task_list_id: number;
    created_at: string;
    updated_at: string;
    status: string;
    task_id: number;
    name: string;
  };
  specification: {
    id: number;
    project_id: number;
    project_rooms_task_lists_task_id: number;
    created_at: string;
    updated_at: string;
    task_id: number;
    specification_name: string;
    area: string | null;
    quantity: string;
    measurement_unit: string | null;
    labour_cost: string;
    material_cost: string;
    unit_total: string;
    rate: string;
    specification_id: number;
    handling_charge: number;
    margin: number;
    total_cost: number | null;
  };
}

interface Meta {
  message: string;
}
export interface Props {
  navigation: any;
  id: string;
  classes: any;
  data: {
    id: string;
    type: string;
    attributes: Section;
  }[];
  meta: Meta;
}

interface BackendErrors {
  projectName?: string;
  name?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  streetName?: string;
  cityName?: string;
  postCode?: string;
  country?: string;
  state?: string;
  additionalInfo?: string;
}

export interface FormValues {
  projectName: string;
  clientDetails: string;
  teamSize: string;
  projectUrl: string;
  developerEmail: string;
  developerFullName: string;
  firstName: string;
  projectDescription: string;
  lastName: string;
  postCode: string;
  city: string;
  email: string;
  streetName: string;
  phoneNumber: string;
  startDate: Date | null;
  endDate: Date | null;
  country: string;
  state: string;
  countryCode: string;
  draft:boolean,
  projectImages: {
    [x: string]: any; [key: number]: FileData[] 
};
  projectDocuments: IPresignUrl[];
  projectImagesUrl: {
    name: string;
    imageUrl?: ImageUrl;
    imageObj: any;
    id?: number | null;
  }[];
}

interface Shell {
  id: number;
  logo: any;
  name: string;
  amount: number;
}
export interface FileData {
  isUploading: boolean;
  section_id? : number;
  file:File;
  file_name: string;
  size: string;
  content_type: string;
  image: File;
  error?: string;
  progress?: number;
  attachment_id :number;
}

export interface Task {
  create_your_own: string;
  id: number;
  title: string;
}
export interface Specification
{
  id: number;
  specification_name: string;
}
interface FileUploaderState {
  fileCounts: { [key: number]: number };
}

interface UploadedFile {
  file_name: string;
  content_type: string;
  section_id: number;
  url: string;
  size: string;
  attachment_id: number;
}

interface UploadProjectResponse {
  [x: string]: any;
  message: string;
  project: {
      id: number;
      name: string;
  };
  uploaded_files: UploadedFile[];
}

interface ProjectDataPropertyType {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      project_name: string;
      start_date: string | null;
      end_date: string | null;
      description: string;
      profile_id: number;
      project_plan_id: number;
      property_type_id: number;
      draft: boolean;
      status: string;
      payment_status: string;
      unique_url: string | null;
      scalings: string | null;
      drawings: string | null;
      step: number;
      pin_status: boolean;
      scope_category_ids: number[];
      project_plan: {
        id: number;
        title: string;
        description: string;
        created_at: string;
        updated_at: string;
        price: number | null;
      };
      files_uploaded: boolean;
      uploaded_files: string | null;
      associated_projects: any[];
      addressable: {
        id: number;
        address: string;
        address2: string;
        city: string;
        country: string;
        email: string;
        name: string;
        last_name: string;
        phone: string;
        post_code: string;
        state: string;
        country_code: string;
        created_at: string;
        updated_at: string;
      };
      chat_channels: any[];
    };
  };
}

interface ShellsWithCountObject {
  category_id:number,
  count?:number
}

interface RoomsWithCountObject {
  room_id:number,
  count?:number
}

interface CreateProjectShellResponse{
  message:string;
  data:CreateProjectShellData[]
}

interface CreateProjectShellData {
  id: number;
  category_id: number;
  bx_block_profile_project_id: number;
  count: number;
  created_at: string; 
  updated_at: string;
}


interface RoomsDataItem{
  id:string,
  logo:string,
  name:string,
  amount:string
}

export interface SearchRoomsDataItem{
  name:string,
  amount:string,
  id:string,
  logo:string,
  width: string,
  height: string,
  depth: string,
  area: string|number|any,
  perimeter: string|number|any,
  checked:boolean
}

interface ProjectRoomAttributes{
  id:number,
  room_id:number,
  bx_block_profile_project_id:number,
  room_name:string,
  width?: any,
  height?: any,
  depth?: any,
  area?: any,
  perimeter?: any,
}


interface ProjectRoom{
  id:string,
  type:string,
  attributes:ProjectRoomAttributes
}

interface GetRoomListStep4Interface{
  data:{
    data: ProjectRoom[]
  }
  total_page:number
  current_page:number
}

interface DropDownSpecification {
  id: string,
  type: string,
  attributes: {
    id: number,
    name: string,
    tasks: {
      id: string,
      title: string,
      description: string
    }[];
  };
};

export interface ShowRoomListDataInterface{
  id: string,
  room_id: string,
  room_name: string,
  width: string,
  height:string,
  depth:string,
  area:string,
  perimeter:string,
}

export interface UpdateRoomDimensions{
  id: string,
  width?: string,
  height?:string,
  depth?:string,
  area?:string,
  perimeter?:string,
}


interface S {
  piToggleDropDown:boolean;
  planId: number | string | null;
  snackbarOpen: boolean,
  snackbarMessage: string
  isDirty:boolean,
  selectedSpecifications: any,
  openDropdownId: any,
  openSpecDropdownId: any,
  openAddTaskDropdownId: any,
  openAddSpecDropdownId: any,
  selectedTask: any;
  selectedTaskId: any;
  roomId: number,
  editedQuantities: any,
  selectedCheckboxes: any,
  openDialogLeave:boolean,
  path: string;
  stateList: { isoCode: string; name: string }[];
  countryList: any[];
  anchorEls: any[];
  token: string;
  loading: boolean;
  currentDate: string;
  isDisabled: boolean;
  projectId: string;
  projectDataId: string;
  formValues: FormValues;
  imageUrl: IImageUrl[];
  documentUrl: IImageUrl[];
  images: IPresignUrl[];
  documents: IPresignUrl[];
  openDialog:boolean
  openDialog1:boolean
  draft:boolean
  formError: {
    projectNameError: string;
    projectDescriptionError: string;
    clientDetailsError: string;
    teamSizeError: string;
    projectUrlError: string;
    developerEmailError: string;
    developerFullNameError: string;
    firstNameError: string;
    lastNameError: string;
    emailError: string;
    streetNameError: string;
    countryCodeError: string;
    cityError: string;
    phoneNumberError: string;
    startDateError: string;
    endDateError: string;
    countryError: string;
    postCodeError: string;
    stateError: string;
  };
  activeStep: number;
  completed: { [k: number]: boolean };
  selectedType: number;
  selectedScopes: number[];
  searchInputText: string;
  shellsSubstructures: Shell[];
  filteredItems: Shell[];
  taskListfilter:any[];
  number:any;
  number1:any;
  materialPopUP:boolean;
  labourPopUP:boolean;
  specialistCostPlanList: Section[];
  uploadedFiles: {
    [x: string]: any; [key: number]: FileData[] 
};
  fileListExpanded: { [key: number]: boolean };
  fileCounts: { [key: number]: number }
  uploadProjectResponse: UploadProjectResponse;
  deleteProjectUploadResponse : {}
  fileNotFound:boolean;
  projectTypes:[object]
  handleNavigation:number
  scopeCategories:any[],
  rooms:any,
  toggle:boolean,
  anchorEl:any,
  uploadedImageFiles: FileData[];
  anchorElT:any
  area:any,
  buttonId:string,
  stages:any,
  checkValue:string,
  selectedItems: any,
  selectedCheckboxes1: any,
  activeButton:boolean,
  taskList:any[],
  taskList1:any[],
  deleteUpadte:boolean,
  projectTotalCost:any,
  getRoomData:any[],
  getStagesData:any[],
  materialList:any[],
  labourRates:any[],
  initialMaterialList:any[],
  dropDownTask:Task[],
  dropDownSpecification:Specification[],
  dropDownSpecification12: DropDownSpecification[],
  tableElT:boolean,
  tableIndex:number  | null,
  subTaskActive:boolean,
  subTaskId:number | null,
  startDateOpen:Date | null,
  endDateOpen:Date | null,
  tempDate:any,
  shellsData:any[]
  errors: {
    projectName: string
  }
  roomsData:any[]
  scopeId:Number
  searchError:boolean,
  backendErrors:BackendErrors,
  openAddMemberDialog:boolean,
  closeAddMemberDialog:boolean
  roomsId:any
  anchorElt3:any
  specificationActive:boolean;
  anchorElTSpeci:any;
  inputFocus:boolean;
  specific1:string;
  specific2:string;
  inputFocus2:boolean;
  selectedOptions:string[];
  measurementUnit:string;
  inputFocus3:boolean;
  openAddTask:boolean;
  inputFocus4:boolean;
  getSpeciIndex1:any;
  getSpeciIndex2:any;
  createOwnTaskData:any;
  taskListId:number | null;
  taskListId1:number | null;
  loader:boolean;
  getAllAddedMemeber: {
    members: [];
    teams: [];
};
 clientName:any;
 clientLastName:any;
 clientEmailAddress:any;
  enableNextBtn:boolean,
  selectPropertyTypeResponse:ProjectDataPropertyType,
  multipleScopesSelected:number[],
  shellsWithCount:ShellsWithCountObject[],
  roomsWithCount:RoomsWithCountObject[],
  createProjectShellsResponse:CreateProjectShellResponse
  addNewTaskPopup:boolean,
  searchRoomsData:SearchRoomsDataItem[],
  searchRoomsDat1:any[],
  payloadRoomsData:{ room_id: number }[],
  payloadRoomsData1:{ project_rooms_task_list_id: number }[],
  selectedAddRoomPopup:SearchRoomsDataItem[],
  searchByRoomText:string,
  searchByRoomText1:string,
  originalRoomsData:SearchRoomsDataItem[],
  roomsCalculatedData:SearchRoomsDataItem[],
  totalArea:number,
  totalPerimeter:number,
  createRoomsResponse:any,
  pageNumber:number,
  showRoomListData:ShowRoomListDataInterface[],
  openDeleteDialog:boolean,
  deleteRoomId:string,
  dimensionRoomsArray:UpdateRoomDimensions[],
  tasksListId: string | null,
  anchorElT1: any,
  roomListId: string | null
  anchorElT11:  HTMLElement | null,
  roomListId1: string | null,
  nextButtonClicked:boolean,
  mesurmentQty:string,
}

interface SS {
  id: any;
}

const shellsMock = [
  {
    id: 1,
    logo: mockIcon,
    name: "Ground Floor – Timber/Brick",
    amount: 0,
  },
  { id: 2, logo: mockIcon, name: "Pitched Roof", amount: 0 },
  {
    id: 3,
    logo: mockIcon,
    name: "Ground Floor – Brick/Block",
    amount: 0,
  },
];

const substructuresMock = [
  {
    id: 1,
    logo: mockIcon,
    name: "Ground Floor – Brick/Block",
    amount: 0,
  },
  { id: 2, logo: mockIcon, name: "Ground Floor – Timber/Brick", amount: 0 },
  {
    id: 3,
    logo: mockIcon,
    name: "Stubbed Attic",
    amount: 0,
  },
];


const selectingRoomMock = [
  {
    id: 1,
    logo: bedImage,
    name: "Ground Floor – Brick/Block",
    amount: 0,
  },
  { id: 2, logo: bedImage, name: "Ground Floor – Timber/Brick", amount: 0 },
  {
    id: 3,
    logo: bedImage,
    name: "Stubbed Attic",
    amount: 0,
  },
];

const RedTextTypography = styled(Typography)({
  color: "#FF0000",
  marginTop: 2,
  fontSize: "small",
  fontFamily: "Poppins",
});

export default class CreateEditProjectPortfolioController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiCreateProjectCallId: string = "";
  apiDraftProjectCallId:string="";
  apiGetProjectbyIDListCallId: string = "";
  getSpecialistCostPlanListCallId: string = "";
  uploadProjectFileCallId: string = "";
  deleteProjectUploadFileCallId: string = "";
  handelStripeCallId: string = "";
  apiGetProjectByIdCallId: string | null = "";
  projectTypeGetCallId:string = '';
  projectScopesGetCallId:string = '';
  shellsGetCallid:string = '';
  roomsGetCallid:string = '';
  createOwnTaskId:string = '';
  getTaskListId:string = '';
  postAutoTaskId:string = '';
  getTaskListId1:string = '';
  apiGetAddedMembersListCallId:string = "";
  apiStepStateChangeCalId:string="";
  selectPropertyTypesApi:string = "";
  calenderDate:string = "";
  createShellsApiCallId:string="";
  addRoomApiCall:string="";
  applyFilterApiCall:string="";
  addtaskListApiCall:string="";
  createRoomsApiCallId:string = "";
  getRoomsListApiCallId:string = "";
  gETStepDropDown:string = "";
  getSepcificationDrop:string = "";
  getAddTaskListData:string="";
  roomFilterDataApi:string="";
  roomFilterDataApi1:string="";
  materailUiApiId:string="";
  materialCostSubmit:string="";
  deleteRoomApiCallId:string="";
  deleteRoomApiId:string = "";
  deleteRoomApiId1:string = "";
  deleteRoomTaskApiId:string = "";
  updateDimensionsApiCallId:string="";
  createTasklistsApiCallId:string= "";
  duplicateRoomApiCallId:string = ""
  duplicateRoomApiId:string = "";
  duplicateTaskApiId:string = "";
  duplicateRoomApiId1:string = "";
  apiHitClientEmailLogin:string="";
  addTaskApiCallID: string = ""
  updateSpecApiCallId: string = "";
  updateTaskApiCallId: string = "";
  updateQuantityApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage),
    ];

    this.state = {
      piToggleDropDown:false,
      snackbarOpen: false,
      snackbarMessage: '',
      selectedCheckboxes: {},
      openDropdownId: null,
      openSpecDropdownId: null,
      openAddTaskDropdownId: null,
      openAddSpecDropdownId: null,
      planId: null,
      isDirty:false,
      path: "",
      clientName:"",
      clientLastName:"",
      editedQuantities: {},
      clientEmailAddress:"",
      selectedSpecifications: {},
      selectedTask: {},
      selectedTaskId: {},
      roomId: 0,
  openDialogLeave:false,
      stateList: [],
      countryList: Country.getAllCountries(),
      anchorEls: [],
      token: "",
      loading: false,
      currentDate: "",
      isDisabled: false,
      projectId: "",
      projectDataId: "",
      openDialog:false,
      openDialog1:false,
      draft:false,
      formValues: {
        projectName: "",
        draft:false,
        clientDetails: "",
        teamSize: "",
        projectUrl: "",
        startDate: null,
        endDate: null,
        developerEmail: "",
        developerFullName: "",
        firstName: "",
        projectDescription: "",
        lastName: "",
        postCode: "",
        city: "",
        email: "",
        streetName: "",
        phoneNumber: "",
        country: "",
        state: "",
        countryCode: "+44",
        projectImages: {},
        projectDocuments: [],
        projectImagesUrl: []
      },
      images: [],
      documents: [],
      imageUrl: [],
      documentUrl: [],
      formError: {
        projectNameError: "",
        projectDescriptionError: "",
        clientDetailsError: "",
        teamSizeError: "",
        projectUrlError: "",
        developerEmailError: "",
        developerFullNameError: "",
        firstNameError: "",
        lastNameError: "",
        emailError: "",
        countryCodeError: "",
        phoneNumberError: "",
        streetNameError: "",
        cityError: "",
        startDateError: "",
        endDateError: "",
        postCodeError: "",
        countryError: "",
        stateError: "",
      },
      activeStep: 0,
      completed: {},
      selectedType: 0,
      selectedScopes: [],
      searchInputText: "",
      shellsSubstructures: [],
      filteredItems: [],
      taskListfilter:[],
      number:"00.00",
      number1:"00.00",
      materialPopUP:false,
      labourPopUP:false,
      specialistCostPlanList:[],
      uploadedFiles: {},
      fileListExpanded: {},
      fileCounts: {},
      uploadProjectResponse: {
        message: '',
        project: {
            id: 0,
            name: ''
        },
        uploaded_files: []
    },
      deleteProjectUploadResponse : {},
      fileNotFound:false,
     
      
      projectTypes:[{}],
      handleNavigation:0,
      scopeCategories:[{}],
      rooms : [
        {
          "Room": "Bedroom","Width": "4.00 m",
          "Depth": "3.50 m","Height": "2.50 m"
        },
        {
          "Room": "Living Room","Width": "5.00 m",
          "Depth": "4.00 m", "Height": "2.80 m"
        },
        {
          "Room": "Kitchen","Width": "3.50 m",
          "Depth": "3.00 m", "Height": "2.40 m"
        },
          {"Room": "Storage Room", "Width": "2.00 m",
          "Depth": "2.50 m","Height": "2.40 m"}
      ],
      toggle:true,
      anchorEl:null,
      uploadedImageFiles: [],
      area:[
        {"Room": "Bedroom","Area":"6.00 m²",
          "Perimeter":"4.00 m"
        },
        {"Room": "Living Room","Area":"2.00 m²","Perimeter":"10.00 m"},
        {"Room": "Kitchen",
          "Area":"5.00 m",
        "Perimeter":"10.00 m"},
        {"Room": 
          "Storage Room",
          "Area":
          "3.00 m²",
        "Perimeter":
        "5.00 m"}
      ],
      buttonId:'',
      openAddMemberDialog:false,
      closeAddMemberDialog:false,
      stages : [],
      checkValue:'',
      selectedItems: {
        button1: new Set(),
        button2: new Set(),
        button3: new Set(),
      },
      selectedCheckboxes1:{},
      activeButton:false,
      taskList: []
      ,
      taskList1:[],
      deleteUpadte:false,
      projectTotalCost:"",
      getRoomData:[],
      getStagesData:[],
    materialList:[],
    labourRates:[
      { task: "Floorboard", material: "18mm Structural Hardwood Plywood Sheet", unit: "m²", cost: 11.4, margin: 10, rate: 12.54 },
      { task: "Timber floor structure", material: "75x200mm C24 joists 400 cts, struts and hangers", unit: "m²", cost: 19.0, margin: 10, rate: 19.8 },
      { task: "Concrete padstone", material: "215x215x102mm", unit: "nr", cost: 20.0, margin: 10, rate: 22.0 },
      { task: "Hopper", material: "Small aluminium powder coated hopper", unit: "nr", cost: 32.0, margin: 10, rate: 35.2 },
      { task: "Waste pipe overground", material: "110mm UPVC pipe", unit: "m", cost: 15.0, margin: 10, rate: 16.5 },
    ],
      initialMaterialList:[],
      dropDownTask:[],
      dropDownSpecification:[],
      dropDownSpecification12:[],
      anchorElT:null,
      tableElT:false,
      tableIndex:0,
      subTaskActive:false,
      subTaskId:0,
      startDateOpen: null,
      endDateOpen: null,
      tempDate:null,
      shellsData:[],
      roomsData:[],
      errors: {
        projectName: ''
      },
      scopeId:0,
      searchError:false,
      backendErrors: {
        projectName:''
      } as BackendErrors,
      roomsId:[],
      anchorElt3:null,
      specificationActive:false,
      anchorElTSpeci:null,
      inputFocus:false,
      specific1:'',
      specific2:'',
      inputFocus2:false,
      selectedOptions:[],
      measurementUnit:'',
      inputFocus3:false,
      openAddTask:false,
      inputFocus4:false,
      getSpeciIndex1:null,
      getSpeciIndex2:null,
      createOwnTaskData:{},
      taskListId:0,
      taskListId1:0,
      loader:false,
      getAllAddedMemeber:{members: [],teams: []},
      enableNextBtn:false,
      selectPropertyTypeResponse:{data:{
        id: "",
        type: "",
        attributes: {
          id: 0,
          project_name: "",
          start_date: null,
          end_date: null,
          description: "",
          profile_id: 0,
          project_plan_id: 0,
          property_type_id: 0,
          draft: false,
          status: "",
          payment_status: "",
          unique_url: null,
          scalings: null,
          drawings: null,
          step: 0,
          pin_status: false,
          scope_category_ids: [],
          project_plan: {
            id: 0,
            title: "",
            description: "",
            created_at: "",
            updated_at: "",
            price: null
          },
          files_uploaded: false,
          uploaded_files: null,
          associated_projects: [],
          addressable: {
            id: 0,
            address: "",
            address2: "",
            city: "",
            country: "",
            email: "",
            name: "",
            last_name: "",
            phone: "",
            post_code: "",
            state: "",
            country_code: "",
            created_at: "",
            updated_at: ""
          },
          chat_channels: []
        }
      }},
      multipleScopesSelected:[],
      shellsWithCount:[],
      roomsWithCount:[],
      createProjectShellsResponse:{
        message: "",
        data: []
      }, 
      addNewTaskPopup:false,
      searchRoomsData:[],
      searchRoomsDat1:[],
      payloadRoomsData:[],
      payloadRoomsData1:[],
      selectedAddRoomPopup:[],
      searchByRoomText:"",
      searchByRoomText1:"",
      originalRoomsData:[],
      roomsCalculatedData:[],
      totalArea:0.00,
      totalPerimeter:0.00,
      createRoomsResponse:{},
      pageNumber:1,
      showRoomListData:[],
      openDeleteDialog:false,
      deleteRoomId:"",
      dimensionRoomsArray:[],      
      anchorElT1:null,
      tasksListId:null,
      roomListId:null,
      anchorElT11: null,
     roomListId1: null,
     nextButtonClicked:false,
     mesurmentQty:''
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const responseData = message.getData(
        "Dummy text because Enum does not exist"
      );
      responseData && this.setState({ projectId: responseData });
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.apiCreateProjectCallId) {
        this.createProjectHandleResponse(responseJson);
      } if (apiRequestCallId === this.apiDraftProjectCallId) {
        this.handleSaveDraftProjectHandleResponse(responseJson);
      } 
      if (apiRequestCallId === this.selectPropertyTypesApi) {
        this.handleSelectPropertyTypesResponse(responseJson)
      } 
      if (apiRequestCallId === this.calenderDate) {
        this.getTaskList1()
        this.handleCalendarClose()
      } 
      if (apiRequestCallId === this.createShellsApiCallId) {
        this.handeleCreateShellsResponse(responseJson)
      } 

      if (apiRequestCallId === this.createRoomsApiCallId) {
        this.handleCreateRoomsResponse(responseJson)
      } 
      if (apiRequestCallId === this.getTaskListId1) { 
        this.setState({taskList1:responseJson.rooms})
        this.setState({projectTotalCost:responseJson.project_cost})
        this.setState({taskListfilter:responseJson.rooms})
        this.setState({loader:false})
      }
    }
    this.receive1(message);
    this.receive2(message);
    this.receive3(message);
    this.receive4(message);
    this.receive5(message);
    this.receive6(message);
    this.receive7(message);
    this.receive8(message);
  }
  getRoomDisplayName = (roomName: string, roomNameCount: Record<string, number>) => {
    if (!roomNameCount[roomName]) {
      roomNameCount[roomName] = 1;  
      return roomName;            
    } else {
      const count = roomNameCount[roomName];  
      roomNameCount[roomName] += 1;           
      return `${roomName} (${count})`;
    }
  };
 receive1=(message:Message)=>{
  if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.getSpecialistCostPlanListCallId) {
      this.specialistCostPlanListResponse(responseJson);
    }

    if (apiRequestCallId === this.projectTypeGetCallId) {
           this.handleProjectTypeData(responseJson)
           
    }

    if (apiRequestCallId === this.projectScopesGetCallId) {
      this.handleScopeCategories(responseJson)
}
    if (apiRequestCallId === this.deleteRoomApiId) {
      this.getTaskList1()
      this.stagesFilterData()
      this.roomFilterData()
      this.setState({ anchorElT1: null, openDeleteDialog: false })
      toast.success('Room Deleted Successfully',{
        closeButton: false,
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        draggable: false,
        pauseOnHover: false,
        closeOnClick: false,
        theme: "light",
      })
    } 
    if (apiRequestCallId === this.deleteRoomApiId1) {
      this.getTaskList1()
      this.stagesFilterData()
      this.roomFilterData()
      this.setState({ anchorElT11: null, openDeleteDialog: false })
      toast.success('Tasklist Deleted Successfully',{
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        closeButton: false,
        theme: "light",
      })
    } 
    if (apiRequestCallId === this.duplicateRoomApiId) {
      this.getTaskList1()
      this.setState({ anchorElT1: null, openDeleteDialog: false })
        toast.success('Room Duplicated Successfully',{
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          closeButton: false,
          theme: "light",
        })
    } 
    if (apiRequestCallId === this.duplicateRoomApiId1) {
      this.getTaskList1()
      this.setState({ anchorElT11: null, openDeleteDialog: false })
      toast.success('Tasklist Duplicated Successfully',{
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        closeButton: false,
        theme: "light",
      })
    } 
  }


 }
 receive2=(message:Message)=>{
  if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.uploadProjectFileCallId) {
      this.setState({uploadProjectResponse:responseJson});
      this.getProjectDetailsById();
    }
    if (apiRequestCallId === this.addRoomApiCall) {
      this.getListOfRoomsStep4()
     this.getTaskList1()
     this.stagesFilterData()
     this.roomFilterData()
     toast.success('Rooms Added Successfully',{
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      closeButton: false,
      theme: "light",
     })
    }  
    if (apiRequestCallId === this.applyFilterApiCall) {
      if(responseJson){
        this.setState({taskList1:responseJson.data})
        this.setState({anchorEl:null})
      }
     }   
    if (apiRequestCallId === this.postAutoTaskId) {
      this.getTaskList1()
      this.roomFilterData()
      this.stagesFilterData()
      this.setState({ searchInputText:""});

     } 
    if (apiRequestCallId === this.deleteRoomTaskApiId) {
      this.getTaskList1()
      this.stagesFilterData()
      this.roomFilterData()
      this.setState({ anchorElT11: null, openDeleteDialog: false,anchorElT1:null })
      toast.success('Task Deleted Successfully',{
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        closeButton: false,
        theme: "light",
      })
    } 
    if (apiRequestCallId === this.duplicateTaskApiId) {
      this.getTaskList1()
      this.setState({ anchorElT1: null, openDeleteDialog: false, anchorElT:null })
      toast.success('Task Duplicated Successfully',{
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        closeButton: false,
        theme: "light",
      })
    } 
  }


 }
 receive3=(message:Message)=>{
  if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.deleteProjectUploadFileCallId) {
      this.setState({deleteProjectUploadResponse:responseJson})
     this.deleteProjectUploadResponse(responseJson);
    }
  }


 }
 receive4=(message:Message)=>{
  if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.handelStripeCallId) {
      this.stripeResponse(responseJson);
    }
    if (apiRequestCallId === this.updateTaskApiCallId) {
      this.getTaskList1();

    }
  }
 }
 receive5=(message:Message)=>{
  if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.apiGetProjectByIdCallId) {
        this.getProjectByIdResponse(responseJson);
       
      }
      if (apiRequestCallId === this.shellsGetCallid) {
        this.handleShellsResponse(responseJson.data)   
          }
      if (apiRequestCallId === this.roomsGetCallid) {
        this.handleRoomsResponse(responseJson.data)
      }
      if (apiRequestCallId === this.addtaskListApiCall) {
       this.getTaskList1()
      } 
    }

  }

}
receive6=(message:Message)=>{
  if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId && responseJson ) {
      if (apiRequestCallId === this.getTaskListId) {
          const formattedData = this.formattedResponse(responseJson)
          this.handleTaskListResponse(formattedData)
      }
      if (apiRequestCallId === this.createOwnTaskId) {
              this.setState({loader:false})
            this.getTaskList1()
          
      }
      if (apiRequestCallId === this.updateQuantityApiCallId) {
        this.handleUpdateQuantityResponse(responseJson)
      }
    }

  }

}
receive7=(message:Message)=>{
  if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId && responseJson ) {
      if (apiRequestCallId === this.apiGetAddedMembersListCallId) {
        this.getAddedMembersListResponse(responseJson);
      }

      if (apiRequestCallId === this.addTaskApiCallID) {
        this.handleAddTaskApiResponse(responseJson)
      }
        
      if (apiRequestCallId === this.gETStepDropDown) {
        this.setState({ dropDownTask: responseJson.tasks })  
      }
      if (apiRequestCallId === this.updateSpecApiCallId) {
        this.handleUpdateSpecResponse(responseJson)
      }
    }
    if (apiRequestCallId === this.roomFilterDataApi) {
      const roomDataArray = responseJson.data?.data || [];
      const rooms = roomDataArray.map((room: any) => ({
        room_name: room.attributes.room_name,
        room_id: room.attributes.room_id,
        id:room.attributes.id
      }));
      this.setState({ getRoomData: rooms });
    } 
   
    if (apiRequestCallId === this.roomFilterDataApi1) {
      const roomDataArray = responseJson.data || [];    
      const rooms = roomDataArray.map((room: any) => ({
        room_name: room.name,
        room_id: room.bx_block_tasks_task_lists_id,
        id:room.id
      }));    
      this.setState({ getStagesData: rooms });
    }
    
  }

}

receive8=(message:Message)=>{
  if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId && responseJson ) {
      if (apiRequestCallId === this.getRoomsListApiCallId) {
          this.getRooomListStep4Response(responseJson)
          this.setState({loader:false})
      }
      if (apiRequestCallId === this.deleteRoomApiCallId) {
          this.setState({openDeleteDialog:false,anchorEl:null})
          setTimeout(()=>{
            this.getListOfRoomsStep4()
          },500)  
          toast.success('Room Deleted Successfully',{
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            closeButton: false,
            theme: "light",
          })   
      }

      if (apiRequestCallId === this.duplicateRoomApiCallId) {
        if(responseJson){
          this.setState({deleteRoomId:'',loader:false})
          this.getListOfRoomsStep4()
          toast.success('Room Duplicated Successfully',{
            position: "top-center",
            hideProgressBar: true,
            autoClose: 5000,
            pauseOnHover: false,
            closeOnClick: false,
            draggable: false,
            theme: "light",
            closeButton: false,
          })
        }
        }
        if(apiRequestCallId==this.updateDimensionsApiCallId){
          this.setState({loader:true,anchorEl:null})
          if(responseJson){
            this.getListOfRoomsStep4()
            if(!this.state.nextButtonClicked && !this.state.deleteUpadte){
            this.duplicateRoomApiCall()}
        }
        }

      if (apiRequestCallId === this.getSepcificationDrop) {
        this.setState({ dropDownSpecification: responseJson.specifications })
    }
    if (apiRequestCallId === this.materailUiApiId) {
      this.setState({materialList:responseJson.data})
      this.setState({initialMaterialList:responseJson.data})
      if(responseJson){
        this.setState({ materialPopUP: true });
      }
  }
  if (apiRequestCallId === this.materialCostSubmit) {
    this.setState({materialPopUP:false,anchorEl:null, number:"00.00",number1:"00.00"})
  } 
      }
      if (apiRequestCallId === this.getAddTaskListData) {
        this.setState({
          dropDownSpecification12: responseJson.data,
          searchRoomsDat1: responseJson.data.map((data:DropDownSpecification) => ({
            ...data,
            checked: false, 
          })),
        });
      }

  }
}
handleClientSuppliedCheckboxChange1 = (subData: any,subDataId: number, initialValue: boolean) => {

  this.setState((prevState) => {
    const updatedSelection = { ...prevState.selectedCheckboxes };

    const currentValue = updatedSelection[subDataId] !== undefined 
      ? updatedSelection[subDataId] 
      : initialValue;

    updatedSelection[subDataId] = !currentValue;

    return { selectedCheckboxes: updatedSelection };
  });
};

handleCheckboxChange1 = (item: any) => {
  this.setState((prevState) => {
    const { buttonId, selectedItems } = prevState;
    const newSelectedItems = new Map(selectedItems[buttonId] || []);
    if (newSelectedItems.has(item.id)) {
      newSelectedItems.delete(item.id);
    } else {
      newSelectedItems.set(item.id, { id: item.id, room_id: item.room_id });
    }

    return {
      selectedItems: {
        ...selectedItems,
        [buttonId]: newSelectedItems,
      },
    };
  });
};
  handleAddTaskApiResponse = (responseJson: any) => {
    if (responseJson.message == "Task and specification added successfully") {
      this.setState({
        specificationActive: false
      }, () => {
        this.getTaskList1()
      })
    }
  };   
  handleUpdateSpecResponse = (responseJson: any) => {
   this.getTaskList1();
  };

  handleUpdateQuantityResponse = (responseJson: any) => {
    this.getTaskList1();
  }

  handleUpdateTaskApiResponse = (responseJson: any) => {
    if(responseJson.message === "Task updated successfully") {
      this.getTaskList1();
    }
  };
formattedResponse = (responseJson:any) => {
  return [
    {
      Room: "Unknown Room",
      StartDate: "Jun 1, 2024",
      EndDate: "Jun 5, 2024",
      TotalEarn: "£ 300.00",
      id: '',
      row: {
        history: [
          {
            date: "Unknown Data",
            customerId: "N/A",
            amount: "£ 50.00",
            subTask: responseJson.data.flatMap((dataItem: any) => {
              return dataItem.attributes.tasks?.flatMap((task: any) => {
                return task.attributes.specifications?.map((spec: any) => {
                  const labourCost = Number(spec.labour_cost || 0);
                  const materialCost = Number(spec.material_cost || 0);
                  const unitCost = labourCost + materialCost;
                  const totalCost = unitCost * (spec.quantity || 0);
                  return {
                    Task: task.attributes.title || "No Data Found",
                    Specifications: spec.specification_name || "Unknown Data",
                    Qty: spec.quantity || "N/A",
                    Totalprice: spec.total_cost || "N/A",
                    Measurementunit: spec.measurement_unit || "N/A",
                    Labourcostunit: spec.labour_cost || "N/A",
                    Materialscostunit: spec.material_cost || "N/A",
                    Unitcost: unitCost.toFixed(2),
                    TotalCost: totalCost.toFixed(2),
                    ClientSupplied: "No",
                    id:dataItem.attributes.id
                  };
                });
              });
            }) || [],
          },
        ],
      },
    },
  ];
}

applyToAll = () => {
  const { materialList, number, number1 } = this.state;

  const updatedList = materialList.map((item) => {
    const cost = parseFloat(item.attributes.unit_total.toString());
    const margin = parseFloat(number.toString());
    const handlingCharge = parseFloat(number1.toString());
    const rate1 = cost * ((margin + handlingCharge) / 100);
    const rate=rate1+cost

    return {
      ...item,
      attributes: {
        ...item.attributes,
        margin,
        rate,
      },
    };
  });

  this.setState({ materialList: updatedList });
};

 getIds(path: any) {
  let parts = path.split("/");

  if (parts.length >= 3) {
    const planId = parts[2];

    if (parts.length >= 5 && parts[3] === "project_id") {
      const projectId = parts[4];
      this.setState({ planId: planId, projectId: projectId });
      return { planId, projectId };
    }
   
    this.setState({ planId: planId, projectId: '' });
    return { planId, projectId: null };
  }
  return { planId: null, projectId: null };
}

  async componentDidMount() {
    let token = await getStorageData("token");
    const path = window.location.pathname
    this.setState({ token: token });
    let date = new Date();
    this.getBackBtnQuoteOverviewClick()
    this.getIds(path);
    this.setState({ currentDate: date.toISOString().split("T")[0] });
    this.getspecialistCostPlanList();
    this.getProjectDetailsById();
    this.getProjectType();
    this.getProjetScopes();
    this.handleGetAddedMembersList();
    this.setState({handleNavigation:Number(path.split("/")[2])})
    this.setState({nextButtonClicked:false})
  }


  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    this.handleUploadedFilesUpdate(prevState);
    this.handleCountryChange(prevState);
    if (prevState.specificationActive && !this.state.specificationActive) {
       this.setState({specific1:'',specific2:'',measurementUnit:''})
    }

    if (prevState.activeStep !== this.state.activeStep && this.state.activeStep === 2) {
      setTimeout(()=>{
        this.getRoomsData()
      },500)  
   }

    if (prevState.activeStep !== this.state.activeStep && this.state.activeStep === 3) {
      setTimeout(()=>{
        this.getListOfRoomsStep4()
      },500)  
   }
    
    if (prevState.activeStep !== this.state.activeStep && this.state.activeStep === 4) {
       this.getTaskList()
       this.gETStepDropDown1()
       this.getAddTaskList()
       this.getTaskList1()
       this.roomFilterData()
       this.stagesFilterData()
    }

   
  
  }
  
  private handleUploadedFilesUpdate(prevState: Readonly<S>) {
    const { uploaded_files } = this.state.uploadProjectResponse;
  
    if (prevState.uploadProjectResponse.uploaded_files !== uploaded_files) {
      const uploadedFilesArray = Array.isArray(uploaded_files)
        ? uploaded_files
        : Object.values(uploaded_files).flatMap((file) => (Array.isArray(file) ? file : [file]));
  
      this.setState((prevState) => ({
        formValues: {
          ...prevState.formValues,
          projectImages: Array.isArray(prevState.formValues.projectImages)
            ? [...prevState.formValues.projectImages, ...uploadedFilesArray]
            : [...uploadedFilesArray]
        }
      }));
    }
    if ( prevState.tempDate !==this.state.tempDate) {
      this.setState({anchorElt3:null})
    }
  }
  handleCountryChangeAndGetState = (
    event: React.ChangeEvent<{ value: unknown }>,
    setFieldValue: any
  ) => {
    const selectedCountryCode = event.target.value as string;
    
    const states = State.getStatesOfCountry(selectedCountryCode);
  
    setFieldValue('country', selectedCountryCode);
    setFieldValue('state', '');
  
    this.setState({ stateList: states || [] });
  };

  private handleCountryChange(prevState: Readonly<S>) {
    const currentCountry = this.state.formValues.country;
    const previousCountry = prevState.formValues.country;
  
    if (currentCountry !== previousCountry) {
      const stateList = State.getStatesOfCountry(currentCountry).map((state) => ({
        isoCode: state.isoCode,
        name: state.name,
      }));
      this.setState({
        stateList,
        formError: {
          ...this.state.formError,
          stateError: "",
        },
      });
    }
    if (
      (prevState.activeStep === 0 || prevState.activeStep === 2) &&
      this.state.activeStep === 1
    ) {  
        this.setState({
          shellsSubstructures: this.state.shellsData,
          filteredItems: this.state.shellsData,
        });
      }
      if (
      (prevState.activeStep === 1 || prevState.activeStep === 3) &&
      this.state.activeStep === 2
      ) {
        this.setState({
          shellsSubstructures:this.state.roomsData,
          filteredItems: this.state.roomsData,
        });
      }
   
    if(prevState.buttonId !== this.state.buttonId){
        this.handleButton()
    }

    if(prevState.anchorEl !== this.state.anchorEl){
        this.setState({activeButton:!this.state.activeButton})
    }
    
  }
  
 
  handleStateChange = (event: React.ChangeEvent<any>) => {
    const selectedState = event.target.value;
    this.setState((prevState) => ({
      formValues: {
        ...prevState.formValues,
        state: selectedState,
      },
      formError: {
        ...prevState.formError,
        stateError: "",
      },
    }));
  };

  getSteps() {
    return [
      "Project Information",
      "Select Shells",
      "Select Rooms",
      this.state.toggle ? "Add Room Dimensions":"Select rooms and all",
      "Task List",
    ];
  }

  handleTitle(text: string) {
    this.setState({
      formValues: { ...this.state.formValues, projectName: text },
      formError: { ...this.state.formError, projectNameError: "" },
    });
  }
  handleProjectDescription(text: string) {
    this.setState({
      formValues: { ...this.state.formValues, projectDescription: text },
      formError: { ...this.state.formError, projectDescriptionError: "" },
    });
  }
  handleClientDetails(text: string) {
    this.setState({
      formValues: { ...this.state.formValues, clientDetails: text },
      formError: { ...this.state.formError, clientDetailsError: "" },
    });
  }
  handleTeamSize(text: string) {
    this.setState({
      formValues: { ...this.state.formValues, teamSize: text },
      formError: { ...this.state.formError, teamSizeError: "" },
    });
  }
  
  handleMarginChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === "") {
      this.setState({ number: "" });
      return;
    }
    if (/^\d*\.?\d{0,2}$/.test(value) && parseFloat(value) <= 1000) {
      this.setState({ number: value });
    }
  };

  handleChangeNumber1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;  
    if (value === "") {
      this.setState({ number1: "" });
      return;
    }
    if (/^\d*\.?\d{0,2}$/.test(value) && parseFloat(value) <= 1000) {
      this.setState({ number1: value.replace(/^0+(\d)/, "$1") });
    }
  };
  
  handletaskClick = () => {
    this.setState({ openAddTask: true })
  }
  handleProjecturl(text: string) {
    this.setState({
      formValues: { ...this.state.formValues, projectUrl: text },
      formError: { ...this.state.formError, projectUrlError: "" },
    });
  }

  handleStartDate(date: Date) {
    this.setState({
      formValues: { ...this.state.formValues, startDate: date },
    });
  }
  handleEndDate(date: Date) {
    this.setState({ formValues: { ...this.state.formValues, endDate: date } });
  }

  handleOpenDialog=()=>{
  this.setState({openDialog:true})
  }

  handleOpenDialogLeave = (path: string) => {
    const { formValues } = this.state;
  
    const isFormEmpty = Object.values(formValues).every((value, index) => {
      return (
        !value ||                         
        value === "+44" ||                        
        (Array.isArray(value) && value.length === 0) ||  
        (typeof value === "object" && !Array.isArray(value) && Object.keys(value).length === 0)
      );
    });
  
    if (isFormEmpty) {
      this.props.navigation.navigate(path);
    } else {
      this.setState({
        path,
        openDialogLeave: true,
      });
    }
  };
  
  handleCloseDialog=()=>{
    this.setState({openDialog:false,openDialogLeave:false,snackbarOpen:false,openDialog1:false})

  }
  handleOpenCLick = () => {
    this.setState({ inputFocus4: true })
  }
  handleCloseClick = () => {
    this.setState({ inputFocus4: false })
  }
  handleCancelClick = () => {
    this.setState({ openAddTask: false })
  }
  handleAddClick = () => {
    this.setState({ openAddTask: false })
  }
  handleBlur = () => {
    this.setState({ inputFocus2: false, getSpeciIndex2: null })
  }
  handleBlur1 = () => {
    this.setState({ inputFocus: false })
  }

  handleFocus1 = () => {
    this.setState({ inputFocus: !this.state.inputFocus })
  }

  handleFocus2 = () => {
    this.setState({ inputFocus2: !this.state.inputFocus2 })
  }

  handleFocus3 = () => {
    this.setState({ inputFocus3: !this.state.inputFocus3 })
  }

  handelBlur2 = () => {
    this.setState({ inputFocus2: false })
  }
  handleBlur3 = () => {
    this.setState({ inputFocus3: false })
  }
  handleBlur4 = () => {
    this.setState({ inputFocus: false, getSpeciIndex1: null })
  }
  imagePicker = async () => {
    try {
      const imageResults = await DocumentPicker.pickMultiple({
        type: [DocumentPicker.types.images],
      });
      let imgData: IPresignUrl[] = this.state.images;
      let newImageUrls: IImageUrl[] = this.state.imageUrl;

      imageResults.forEach((item: DocumentPickerResponse) => {
        imgData.push({
          file_name: item.name,
          file_size: item.size ? item.size : 0,
          category: "image",
        });
      });
      imageResults.forEach((item: DocumentPickerResponse) => {
        newImageUrls.push({
          name: item.name,
          type: item.type,
          uri: item.uri,
        });
      });
      this.setState({ images: imgData, imageUrl: newImageUrls });
    } catch (error) {}
  };
  docPicker = async () => {
    try {
      const documentResults = await DocumentPicker.pickMultiple({
        type: [DocumentPicker.types.allFiles],
      });
      let documentData: IPresignUrl[] = this.state.documents;
      let newDocumentUrls: IImageUrl[] = this.state.documentUrl;
      documentResults.forEach((item: DocumentPickerResponse) => {
        documentData.push({
          file_name: item.name,
          file_size: item.size ? item.size : 0,
          category: "document",
        });
      });
      documentResults.forEach((item: DocumentPickerResponse) => {
        newDocumentUrls.push({
          name: item.name,
          type: item.type,
          uri: item.uri,
        });
      });
      this.setState({ documents: documentData, documentUrl: newDocumentUrls });
    } catch (error) {}
  };
  handleEmail(text: string) {
    let regex = isEmail("email", text);
    if (regex.status === false) {
      this.setState({
        formValues: { ...this.state.formValues, developerEmail: text },
        formError: {
          ...this.state.formError,
          developerEmailError: "Please enter valid email",
        },
      });
      return false;
    } else {
      this.setState({
        formValues: { ...this.state.formValues, developerEmail: text },
        formError: { ...this.state.formError, developerEmailError: "" },
      });
      return true;
    }
  }
  handleFullName(text: string) {
    this.setState({
      formValues: { ...this.state.formValues, developerFullName: text },
      formError: { ...this.state.formError, developerFullNameError: "" },
    });
  }
  handlePhoneNumber(text: string) {
    this.setState({
      formValues: { ...this.state.formValues, phoneNumber: text },
      formError: { ...this.state.formError, phoneNumberError: "" },
    });
  }

  updateCountryCode = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState(
      {
        formValues: {
          ...this.state.formValues,
          countryCode: event.target.value as string,
        },
      },
      this.validateCountryCode
    );
  };

  validateCountryCode = () => {
    const { countryCode } = this.state.formValues;
    let countryCodeErrorMsg = "";
    if (!countryCode) {
      countryCodeErrorMsg = "Select the country code";
    }
    this.setState({
      formError: {
        ...this.state.formError,
        countryCodeError: countryCodeErrorMsg,
      },
    });
  };

  createProjectHandleResponse = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({ loading: false });
      setStorageData("project_id",responseJson.data.attributes.id)
      this.handleStepStateApi(this.state.activeStep+1,responseJson.data.id);
      this.navigateToCostPlanUpload(responseJson.data.attributes.id);
    } else if (responseJson.error || responseJson?.errors) {
      this.setState({ loading: false, isDisabled: false,
        backendErrors: { ...this.state.backendErrors, projectName: responseJson.errors[0].project_name }
      });
     

      this.showAlert(
        "Error",
        responseJson.error || responseJson.errors.toString()
      );
    }
  };
  
  handleStepStateApi=(activeStep:number,projectId?:string)=>{
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const id=projectId?projectId:this.state.projectId;
    this.setState({ loading: true });
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.apiStepStateChangeCalId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/projects/${id}/update_project_steps?step=${activeStep}`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify("")
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSaveDraftProjectHandleResponse = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({ loading: false });
      setStorageData("project_id",responseJson.data.attributes.id)
      this.props.navigation.navigate("ProjectPortfolio");
    } else if (responseJson?.error || responseJson?.errors) {
      this.setState({ loading: false, isDisabled: false,
        backendErrors: { ...this.state.backendErrors, projectName: responseJson.errors[0].project_name }
      });
     
    }
  };

  

  getPlanName = (planId: string | number | null) => {
    switch (planId) {
      case "1":
        return "automated_cost_plan";
      case "2":
        return 'manual_cost_plan';
      case "3":
        return "specialist_cost_plan";
      default:
        break;
    }
  }

handleSaveDraftUploadFile=()=>{
  const message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(
    getName(MessageEnum.NavigationTargetMessage),
    "ProjectPortfolio"
  );
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(message);
  
}


handleCostInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
  let value = e.target.value;

  if (value === "") {
    this.updateMaterialList(index, { cost: "" });
    return;
  }

  if (/^\d{0,8}(\.\d{0,2})?$/.test(value)) {
    this.updateMaterialList(index, { cost: value });
  }
};

handleMarginInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
  let value = e.target.value.replace('%', '').trim();
  if (value === "") {
    this.updateMaterialList(index, { margin: "" });
    return;
  }
  if (/^\d{0,8}(\.\d{0,2})?$/.test(value) && parseFloat(value) <= 1000) {
    this.updateMaterialList(index, { margin: value });
  }
};


updateMaterialList = (index: number, updates: { cost?: any; margin?: any }) => {
  this.setState((prevState) => {
    const updatedList = prevState.materialList.map((item, idx) => {
      if (idx === index) {
        const cost = updates.cost !== undefined ? updates.cost : parseFloat(item.attributes.unit_total);
        const margin = updates.margin !== undefined ? updates.margin : parseFloat(item.attributes.margin);
        const handlingCharge = parseFloat(prevState.number1); 
        const rate1 = cost * ((margin + handlingCharge) / 100);
        const rate=rate1+cost

        return {
          ...item,
          attributes: {
            ...item.attributes,
            unit_total: cost.toString(),
            margin: margin.toString(),
            rate: rate.toString(),
          },
        };
      }
      return item;
    });

    return { materialList: updatedList };
  });
};

selectCalenderDate1 = async()=>{
  const token = await getStorageData("token")    
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.materialCostSubmit = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tasks/task_list_tasks_specifications/bulk_update`
    );

    
    const body = {
      task_list_specifications: this.state.materialList.map((item: any) => ({
        id: item.id,
        project_id: item.project_id,
        specification_name: item.attributes?.specification_name || "",
        measurement_unit: item.attributes?.measurement_unit || "",
        unit_total: parseFloat(item.attributes?.unit_total) || 0,
        handling_charge: this.state.number1 ,
        rate: parseFloat(item.attributes?.rate) || 0,
        margin: parseFloat(item.attributes?.margin) || 0,
      })),
    };
    
    
     requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

handleSaveDraftAPI = async () => {
  const { formValues, planId } = this.state;
  const currentPath = window.location.pathname;
  const projectIdMatch = currentPath.match(/\/project_id\/(\d+)/);
  const isUpdate = projectIdMatch !== null;

  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: this.state.token,
  };

  this.setState({
    loading: false,
    openDialog: false,
    draft: true,
    openDialogLeave: false,
  });

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.apiDraftProjectCallId = requestMessage.messageId;

  const commonAttributes = {
    address: formValues.streetName,
    country: formValues.country,
    post_code: formValues.postCode,
    address2: formValues.streetName,
    city: formValues.city,
    state: formValues.state
  };

  const optionalAttributes = planId === 3 
    ? { email: null, name: null, last_name: null, phone: null }
    : { email: formValues.email, name: formValues.firstName, last_name: formValues.lastName, phone: formValues.phoneNumber };

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    isUpdate && projectIdMatch ? `bx_block_profile/projects/${projectIdMatch[1]}` : "bx_block_profile/projects"
  );

  const body = {
    project: {
      plan_type: await this.getPlanName(planId),
      project_name: formValues.projectName,
      description: formValues.projectDescription,
      draft: "true",
      status: 'Draft',
      addressable_attributes: {
        ...commonAttributes,
        ...optionalAttributes,
      },
    },
  };

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    isUpdate ? "PUT" : "POST"
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};
submitData = async () => {
  const { formValues, planId } = this.state;

  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: this.state.token,
  };

  this.setState({ loading: true });

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiCreateProjectCallId = requestMessage.messageId;


  const commonAttributes = {
    address: formValues.streetName,
    address2: formValues.streetName,
    city: formValues.city,
    country: formValues.country,
    post_code: formValues.postCode,
    state: formValues.state,
  };


  const optionalAttributes = planId === 3 
    ? { email: null, name: null, last_name: null, phone: null }
    : { email: formValues.email, name: formValues.firstName, last_name: formValues.lastName, phone: formValues.phoneNumber,country_code:formValues.countryCode };

  
  const body = {
    project: {
      plan_type: await this.getPlanName(planId),
      project_name: formValues.projectName,
      description: formValues.projectDescription,
      draft: "true",
      status:"Draft",
      files_uploaded : false,
      addressable_attributes: {
        ...commonAttributes,
        ...optionalAttributes,
      },
      project_plan_id:planId
    },
  };

  
  const currentPath = window.location.pathname;
  const projectIdMatch = currentPath.match(/\/project_id\/(\d+)/); 
  const isUpdate = projectIdMatch !== null;
  if (isUpdate && projectIdMatch && projectIdMatch[1]) {
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_profile/projects/${projectIdMatch[1]}`
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PUT"
    );
} else {
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createProjectEndPoint
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postAPiEndMethod
    );
}

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );

  
  runEngine.sendMessage(requestMessage.id, requestMessage);
};

getTrimmedValues = (values: FormValues) => ({
  projectName: values.projectName.trim(),
  firstName: values.firstName.trim(),
  lastName: values.lastName.trim(),
  email: values.email.trim(),
  phoneNumber: values.phoneNumber.trim(),
  streetName: values.streetName.trim(),
  city: values.city.trim(),
  postCode: values.postCode.trim(),
  country: values.country.trim(),
  state: values.state.trim(),
  projectDescription: values.projectDescription.trim(),
  countryCode:values.countryCode
});
handleSubmit = (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
  const trimmedValues = this.getTrimmedValues(values);
  localStorage.setItem("firstNameClient",this.state.clientName)
  localStorage.setItem("lastClientName",this.state.clientLastName)
  localStorage.setItem("emailOfTheClient",this.state.clientEmailAddress)

  this.setState({
    formValues: {
      ...this.state.formValues,
      ...trimmedValues,
    },
  },()=>{this.submitData();resetForm();});
  

 
  ;
};

handleSaveDraft = (values: FormValues) => {
  const trimmedValues = this.getTrimmedValues(values);

  this.setState(
    {
      formValues: {
        ...this.state.formValues,
        ...trimmedValues,
        draft: true,
      },
    },
    () => {
      this.handleSaveDraftAPI(); 
      this.handleCloseDialog();
    }
  );

};




getError = (fieldName: keyof FormValues, formik: any, backendErrors:any = {}) => {
  const isTouched = formik.touched[fieldName];
  const validationError = formik.errors[fieldName];
  const backendError = backendErrors[fieldName];

  if (backendError) {
    return backendError;
  }

  if (isTouched && validationError) {
    return validationError;
  }

  return '';
};

  saveDraftDisable = () => {
    const obj = {
      projectName: this.state.formValues.projectName,
      projectDescription: this.state.formValues.projectDescription,
      postCode: this.state.formValues.postCode,
      city: this.state.formValues.city,
      streetName: this.state.formValues.streetName,
      country: this.state.formValues.country,
      state: this.state.formValues.state,
    };
    return Object.values(obj).some(value => value.trim() !== '');
  }

  handleScopeClick = (selectedScope: number,selected:boolean) => {

    const scopeCategories = this.state.scopeCategories.map((scope)=>{
      if(scope.id === selectedScope){
        return {
          ...scope,
          selected:!selected
        }
      }
      return scope
    })

    this.setState({scopeCategories},()=>{
      this.getMultipleSelectScopeIds()
    })
    localStorage.setItem("scopeId",JSON.stringify(selectedScope))
    this.setState({scopeId:selectedScope})
  };

  handleTypeClick = (selectedType: number) => this.setState({ selectedType });

  handleAmountChange = (id: number, action: string) => {
    let itemsCopy = [...this.state.shellsSubstructures]; 
    const selectedItemIndex = itemsCopy.findIndex((item) => item.id === id);
    switch (action) {
      case "add":
        itemsCopy[selectedItemIndex].amount += 1;
        const addId = Number(id);
        let stored = this.state.roomsId || []; 
        if (!Array.isArray(stored)) {
          stored = [stored]; 
        }
        if (!stored.includes(addId)) {
          stored.push(addId); 
        }
        this.setState({ roomsId: stored });
        break;
    
      case "remove":
        if (itemsCopy[selectedItemIndex].amount !== 0) {
          itemsCopy[selectedItemIndex].amount -= 1;
          const filteredIds = this.state.roomsId.filter((currentId: number) => currentId !== Number(id));
          this.setState({ roomsId: filteredIds });
        }
        break;
    
      default:
        break;
    }

    itemsCopy = itemsCopy.sort((a, b) => {
      if (a.amount !== 0 && b.amount === 0) {
          return -1; 
      }
      if (a.amount === 0 && b.amount !== 0) {
          return 1;
      }
      return a.id - b.id; 
    });
    
    this.setState({ shellsSubstructures: itemsCopy },()=>{
      this.createShellsWithCount()
      this.createRoomsWithCount()
    });
  };


planLogo = (type: string, isError: boolean) => {
    const iconColor = isError ? "#DC2626" : "#237182";
    switch (type) {
      case "image/jpeg":
      case "image/jpg":
        return <JpgIcon color={iconColor} />;
      case "image/png":
        return <PngIcon color={iconColor} />;
      default:
        return <PdfIcon color={iconColor} />;
    }
};


handleFileChange = async (sectionId: number, acceptedFiles: File[] | File) => {
 
  const filesArray = Array.isArray(acceptedFiles) ? acceptedFiles : [acceptedFiles];
  
  
  const currentCount = this.getFileCountBySection(sectionId);
  const MAX_FILES = 5;

  
  if (currentCount + filesArray.length > MAX_FILES) {
    const remainingSlots = MAX_FILES - currentCount;
    const filesToUpload = filesArray.slice(0, remainingSlots);

    for (const file of filesToUpload) {
      await this.handleFileUpload(file, sectionId);
    }

    if (remainingSlots < filesArray.length) {
      console.warn(`Only ${remainingSlots} more files were allowed for section ${sectionId}`);
    }
  } else {
  
    for (const file of filesArray) {
      await this.handleFileUpload(file, sectionId);
    }
  }
};

handleFileUpload = async (file: File, sectionId: number) => {
  const MAX_FILE_SIZE_MB = 10;
  const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

  if (file.size > MAX_FILE_SIZE_BYTES) {
    this.addFileError(sectionId, file, `File exceeds maximum size of ${MAX_FILE_SIZE_MB} MB`);
    return;
  }

  if (this.checkForDuplicateFile(file.name, sectionId)) {
    this.addFileError(sectionId, file, 'File already exists');
    return; 
  }

  this.trackFileUploadProgress(file, sectionId);
  this.getFileCountBySection(sectionId);
};
getFileCountBySection = (sectionId: number): number => {
  const { projectImages } = this.state.formValues;

  const sectionFiles = Object.values(projectImages)
    .flat()
    .filter((fileData) => fileData.section_id === sectionId);

  const sectionFileCount = sectionFiles.length;

  this.setState((prevState) => ({
    fileCounts: {
      ...prevState.fileCounts,
      [sectionId]: sectionFileCount,
    },
  }));

  return sectionFileCount;
};

checkForDuplicateFile(fileName: string, sectionId: number): boolean {
  const projectImages = this.state.formValues.projectImages;
  const fileNamesForSection = Object.values(projectImages)
    .flat()
    .filter((fileData) => fileData.section_id === sectionId)
    .map((fileData) => fileData.file_name);
  
  return fileNamesForSection.includes(fileName);
}

addFileError(sectionId: number, file: File, error: string) {
  this.setState((prevState) => {
    const updatedImages = { ...prevState.formValues.projectImages };
    const existingImages = Array.isArray(updatedImages[sectionId]) ? updatedImages[sectionId] : [];
    
    updatedImages[sectionId] = [
      ...existingImages,
      {
        section_id: sectionId,
        file_name: file.name,
        error: error,
        file: file,
        size: "",
        content_type: "",
        image: undefined,
        attachment_id: 0
      } as unknown as FileData
    ];

    return {
      formValues: {
        ...prevState.formValues,
        projectImages: updatedImages,
      },
    };
  });
}

trackFileUploadProgress = (file: File, sectionId: number) => {
  this.setState((prevState) => {
    const updatedImages = { ...prevState.formValues.projectImages };
    const existingImages = Array.isArray(updatedImages[sectionId]) ? updatedImages[sectionId] : [];

   
    if (existingImages.some((uploadedFile) => uploadedFile.file_name === file.name && uploadedFile.isUploading)) {
     
      return prevState; 
    }

  
    updatedImages[sectionId] = [
      ...existingImages,
      {
        section_id: sectionId,
        file_name: file.name,
        progress: 0,
        isUploading: true,
        error: "",
        file: file,
        size: file.size.toString(),
        content_type: file.type,
        image: undefined,
        attachment_id: 0,
      } as unknown as FileData,
    ];

    return {
      ...prevState,
      formValues: {
        ...prevState.formValues,
        projectImages: updatedImages,
      },
    };
  });


  const uploadInterval = setInterval(() => {
    this.setState((prevState) => {
      const updatedImages = { ...prevState.formValues.projectImages };
      const filesInSection = Array.isArray(updatedImages[sectionId]) ? updatedImages[sectionId] : [];
      const uploadingFileIndex = filesInSection.findIndex(
        (uploadedFile) => uploadedFile.file_name === file.name && uploadedFile.isUploading
      );

      if (uploadingFileIndex === -1) return prevState; 
      const currentFile = filesInSection[uploadingFileIndex];
      const newProgress = Math.min((currentFile.progress || 0) + 10, 100);

      const updatedFiles = filesInSection.map((uploadedFile, index) => {
        if (index === uploadingFileIndex) {
          return { ...uploadedFile, progress: newProgress };
        }
        return uploadedFile;
      });

      updatedImages[sectionId] = updatedFiles;

      const nextState = {
        ...prevState,
        formValues: {
          ...prevState.formValues,
          projectImages: updatedImages,
        },
      };

      if (newProgress === 100) {
        clearInterval(uploadInterval);
        this.updateUploadStatus(sectionId, file);
        this.uploadProjectFile(sectionId, file);
      }

      return nextState;
    });
  }, 200);
};



checkUploadCompletion(sectionId: number, file: File, uploadInterval: NodeJS.Timeout) {

  this.setState((prevState) => {
    const updatedImages = { ...prevState.formValues.projectImages };
    const filesInSection = Array.isArray(updatedImages[sectionId]) ? updatedImages[sectionId] : [];

    const isComplete = filesInSection.some(
      (uploadedFile) => uploadedFile.file_name === file.name && uploadedFile.progress === 100 && uploadedFile.isUploading
    );

    if (isComplete) {
      clearInterval(uploadInterval);
      this.updateUploadStatus(sectionId, file);
      this.uploadProjectFile(sectionId, file); 
    }
  });
}

updateUploadStatus(sectionId: number, file: File) {
  this.setState((innerState) => {
    const updatedImages = { ...innerState.formValues.projectImages };
    const files = updatedImages[sectionId] || [];

    const updatedFiles = files.map((uploadedFile) =>
      uploadedFile.file_name === file.name ? { ...uploadedFile, isUploading: false } : uploadedFile
    );

    updatedImages[sectionId] = updatedFiles;

    return {
      formValues: {
        ...innerState.formValues,
        projectImages: updatedImages,
      },
    };
  });
}
uploadProjectFile = async (sectionId: number, files: File | File[]) => {


   const token = await getStorageData("token");
  const formData = new FormData();
  formData.append("project_id", this.state.projectId.toString());

  const fileArray = Array.isArray(files) ? files : [files];

  fileArray.forEach((fileDetail) => {
    if (fileDetail instanceof File) {
      formData.append(`files[][section_id]`, sectionId.toString());
      formData.append(`files[][file_name]`, fileDetail.name);
      formData.append(`files[][content_type]`, fileDetail.type);
      formData.append(`files[][file]`, fileDetail);
    } else {
      console.warn(`Expected file but received: ${fileDetail}`);
    }
  });

  try {
    const header = {
      "token": token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.uploadProjectFileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadProjectFileAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.uploadProjectFileAPiEndMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    await new Promise((resolve) => setTimeout(resolve, 500));
    runEngine.sendMessage(requestMessage.id, requestMessage);
   
    
    return true;
  } catch (error) {
    console.error(`Error in upload for section ${sectionId}:`, error);
    return false;
  }
};
handleFileDelete = async (sectionId: number, fileName: string, error: string = '') => {
  const { projectImages } = this.state.formValues;

  const attachmentIdsToRemove = this.getAttachmentIdsToRemove(sectionId, fileName, projectImages);

  if (error) {
    this.updateStateForError(sectionId, fileName);
  } else {
    await this.handleFileDeletion(sectionId, fileName, attachmentIdsToRemove);
  }
};

getAttachmentIdsToRemove = (sectionId: number, fileName: string, projectImages: { [key: number]: FileData[] }): number[] => {
  return Object.values(projectImages)
    .flat()
    .filter((file: FileData) => file.file_name === fileName && file.section_id === sectionId)
    .map(file => file.attachment_id);
};

updateStateForError = (sectionId: number, fileName: string) => {
  this.setState(prevState => {
    const updatedProjectImages = this.removeFileFromImages(prevState.formValues.projectImages, fileName);
    const updatedFileCounts = {
      ...prevState.fileCounts,
      [sectionId]: Math.max((prevState.fileCounts[sectionId] || 0) - 1, 0)
    };

    return {
      formValues: { ...prevState.formValues, projectImages: updatedProjectImages },
      fileCounts: updatedFileCounts,
    };
  });
  this.getProjectDetailsById()
};

handleFileDeletion = async (sectionId: number, fileName: string, attachmentIdsToRemove: number[]) => {
  if (attachmentIdsToRemove.length > 0) {
    const deleteSuccess = await this.deleteProjectUploadFile(sectionId, fileName, String(attachmentIdsToRemove[0]));
    if (deleteSuccess) {
      this.setState(prevState => {
        const updatedProjectImages = this.removeFileFromImages(prevState.formValues.projectImages, fileName);
        const updatedFileCounts = {
          ...prevState.fileCounts,
          [sectionId]: Math.max((prevState.fileCounts[sectionId] || 0) - 1, 0)
        };

        return {
          formValues: { ...prevState.formValues, projectImages: updatedProjectImages },
          fileCounts: updatedFileCounts,
        };
      });
    } else {
      this.getProjectDetailsById();
    }
  }
};

removeFileFromImages = (projectImages: { [key: number]: FileData[] }, fileName: string): { [key: number]: FileData[] } => {
  return Object.entries(projectImages).reduce((result, [key, files]) => {
    result[parseInt(key)] = Array.isArray(files)
      ? files.filter((file: FileData) => file.file_name !== fileName)
      : files;
    return result;
  }, {} as { [key: number]: FileData[] });
};




isSubmitDisabled = () => {
  const { formValues } = this.state;
  let isMandatoryIncomplete = false;

  this.state.specialistCostPlanList.forEach((section) => {
    
      const sectionId = section.attributes.id;
      const isMandatory = section.attributes.mandatory;

      const hasImages = Array.isArray(formValues.projectImages) 
          ? formValues.projectImages.filter(image => {
             

              return image.section_id === sectionId; 
          })
          : [];
      
      if (isMandatory && hasImages.length === 0) {
          isMandatoryIncomplete = true;         
      } 
  });

  
 
  const isError = Object.values(formValues.projectImages).flat().some(file => file.error);
 
  return isMandatoryIncomplete || isError; 
};
isSaveDraftDisabled = () => {
  const { formValues } = this.state;
  let hasAnyImages = false; 

  if (Array.isArray(formValues.projectImages)) {
    hasAnyImages = formValues.projectImages.some(image => image.section_id);
  }

  const isError = Object.values(formValues.projectImages).flat().some(file => file.error);
  const shouldDisable = !hasAnyImages || isError;


  return shouldDisable; 
};

deleteProjectUploadFile = async (section_id: number, file_name: string, attachment_id: string): Promise<boolean> => {
  try {
    const token = await getStorageData("token");
    const project_id = this.state.projectId;

    const header = {
      "token": token,
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteProjectUploadFileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteProjectUploadFileAPiEndPoint}/${project_id}/delete_file/${attachment_id}`
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.deleteProjectUploadFileAPiEndMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return  true;
  } catch (error) {
    console.error("Error deleting file:", error);
    return false;
  }
};

deleteProjectUploadResponse=(responseJson:any)=>{
if(responseJson){
  this.getProjectDetailsById()
return true

}
}

navigateToType = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ProjectType"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  navigateToShells = (projectId:string) => {
    storage.set('activeStep',1)
    this.setState({projectId})
    this.selectPropertyTypesAndScopesApiCall()
  };

  navigationToShellsFromScopes = ()=>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ProjectPortfolioShells"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), `${this.state.handleNavigation}/project_id/${this.state.projectId}`);
    this.send(message);
  }

  handleSearchInputChange = (search: string) => {
    const filteredItems = this.state.shellsSubstructures.filter((shell) =>
      shell.name.toLowerCase().includes(search.toLowerCase())
    );
    this.setState({ filteredItems, searchInputText: search , searchError: filteredItems.length === 0 });
  };

  handleSearchInputChange1 = (search: string) => {
    const taskList1 = this.state.taskListfilter.filter((shell) =>
      shell.room_name
    .toLowerCase().includes(search.toLowerCase())
    );
    this.setState({ taskList1, searchInputText: search , searchError: taskList1.length === 0 });
  };

  navigateToScopes = (project_id:string) => {
    this.setState({projectId:project_id})
    this.selectPropertyTypesAndScopesApiCall()

  };

  navigateToProjectScope = ()=>{
    storage.remove('activeStep')
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ProjectScope"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), `${this.state.handleNavigation}/project_id/${this.state.projectId}`);
    this.send(message);
  }

  navigateToCostPlanUpload = (project_id:any) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
       this.state.handleNavigation !== 3 ? "ProjectType" : "ProjectCostPlanUpload"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), `${this.state.handleNavigation}/project_id/${project_id}`);
    this.send(message);
  };

  getspecialistCostPlanList = async() => {
    this.setState({loading:true})
    const token = await getStorageData("token") 
    const header = {
      "token":token,
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSpecialistCostPlanListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSpecialistCostPlanListAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  } 

  specialistCostPlanListResponse = (responseJson:any) => {
    this.setState({specialistCostPlanList:responseJson.data,loading:false});
  }
 


  handleStripe = async() => {
    const token = await getStorageData("token");
    const plan_id = 3
    const project_id = await getStorageData("project_id"); 
    const header = {
      "token":token,
      "Content-Type": configJSON.validationApiContentType,
    };
    const body = {
     plan_id: plan_id,
     files_uploaded: true
    };  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.handelStripeCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.stripeAPiEndPoint}/${project_id}/pay_specialist_plan`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.stripeAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  stripeResponse=(responseJson:any)=>{
    window.location.href = responseJson.checkout_url;
  }
  handleShellsBack=()=>{
    const { buttonId, selectedItems } = this.state;  

    this.setState({
      selectedItems: {
        ...selectedItems,
        [buttonId]: new Set(),
      }
    })
    if(this.state.activeStep===1){
      storage.set('activeStep',this.state.activeStep)
      this.goBack();
    }else{
      this.setState({activeStep:this.state.activeStep-1});
      storage.set('activeStep',Number(this.state.activeStep)-1)
    }
  }
  goBack = (projectId?:string) => {
    if (this.state.activeStep <=1&&!projectId) {
      this.props.navigation.goBack();
      return;
    } else {
      this.setState((prevState) => ({ activeStep: prevState.activeStep - 1 }));
    }
    this.setState(
      (prevState) => ({ activeStep: prevState.activeStep - 1 }),
      () => {
        const targetUrl = `${this.state.planId}/project_id/${projectId}`;
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "CreateEditProjectPortfolio");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationScreenNameMessage), targetUrl);
        this.send(message);
      }
 );
  };
  handleCheckValues=(formik:any)=>{
    const {projectName,firstName,lastName,email,countryCode,phoneNumber,streetName,state,country,postCode,city}=formik.values;
    if(this.state.planId==="3"){
      return (projectName===""||streetName===""||city===""||postCode===""||country===""||state==="");
    }else{
      return (projectName===""||streetName===""||city===""||postCode===""||country===""||state===""||email===""||firstName===""||lastName===""||countryCode===""||phoneNumber==="")
    }
  }

  checkFormvaluesCondtion = (formik: any) => {
    const {
      projectName,
      firstName,
      lastName,
      email,
      phoneNumber,
      streetName,
      state,
      country,
      postCode,
      city,
    } = formik.values;

  
    const formValues = [
      projectName,
      firstName,
      lastName,
      email,
      phoneNumber,
      streetName,
      state,
      country,
      postCode,
      city,
    ];
  
    return formValues.some(value => value !== "");
  };
  
  
  goBack2 = (formik:any) => {
    if(!this.handleToggleButton(formik.errors) && this.checkFormvaluesCondtion(formik)){
      setTimeout(()=>{
        this.setState({openDialogLeave:true})
      })
    }else{
    if (this.state.activeStep <= 1) {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ProjectPlan"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
    } else {
      this.setState((prev) => ({ activeStep: prev.activeStep - 1 }));
    }
  }

};

  getProjectDetailsById=()=>{
    const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: this.state.token,
  };



  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiGetProjectByIdCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getProjectByIdAPIEndPoint}/${this.state.projectId}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getAPiEndMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);


}

  getProjectType = async() => {
    const token = await getStorageData("token") 
    const header = {
      "token":token,
      "Content-Type": configJSON.validationApiContentType,
    };
    
    this.setState({loading:true})

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectTypeGetCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectTypeEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  } 

  handleProjectTypeData = (res:any)=>{
         const data = res.data.map((item:any)=>{
           return { id : item.attributes.id ,
                   icon :  item.attributes.icon_url,
                   title:item.attributes.name,
                   description:item.attributes.description}
          })
                this.setState({projectTypes:data,loading:false})
         
  }

  getProjetScopes = async() => {
    const token = await getStorageData("token") 
    const header = {
      "token":token,
      "Content-Type": configJSON.validationApiContentType,
    };

    this.setState({loading:true})

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectScopesGetCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectScopesCategoriesEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  } 

  handleScopeCategories = (res:any)=>{
    const data = res.map((item:any)=>{
      return {
        id: item.id,
        icon: item.image_url,
        title: item.name,
        description: item.description,
        selected:false,
      }
    })
    this.setState({scopeCategories:data,loading:false})
  }
  handleTogglePIDropDown = ()=>{
    this.setState((prevState) => ({
      piToggleDropDown: !prevState.piToggleDropDown,
    }));
  }
  handleCondition = (condition:any,True:any,False:any)=>{
       return condition ? True :False
  }

  handleIconClick = () => {
    this.setState({inputFocus4: !this.state.inputFocus4});
  };

  handleChangePage = (val: boolean) => {
      this.setState({toggle:val})
  }

  handleClose = ()=>{
      this.setState({anchorEl:null})
  }
  
  handlePopup = (event: React.MouseEvent<HTMLButtonElement>,id:string) => {
    this.setState({ anchorEl: event.currentTarget , buttonId:id });
  };
  getProjectByIdResponse=(responseJson:any) =>{
    this.setState({
      formValues:{projectName: responseJson.data.attributes.project_name,
      clientDetails: "",teamSize: "", projectUrl: "",
      developerEmail: "",
      developerFullName: "",
      projectDescription: responseJson.data.attributes.description,
      firstName: responseJson.data.attributes.addressable.name,
      postCode: responseJson.data.attributes.addressable.post_code,
      lastName: responseJson.data.attributes.addressable.last_name,
      email: responseJson.data.attributes.addressable.email,
      city: responseJson.data.attributes.addressable.city,
      phoneNumber: responseJson.data.attributes.addressable.phone,
      streetName: responseJson.data.attributes.addressable.address,
      startDate: responseJson.data.attributes.start_date,
      country: responseJson.data.attributes.addressable.country,
      endDate: responseJson.data.attributes.end_date,
      countryCode: responseJson.data.attributes.addressable.country_code,
      state: responseJson.data.attributes.addressable.state,
      draft: responseJson.data.attributes.draft,
      projectDocuments: [],
      projectImagesUrl: [],
      projectImages: responseJson.data.attributes.uploaded_files || [],
    }})}

  handleTaskListPopup = (event: React.MouseEvent<HTMLElement>, projectRoomId: string) => {
    this.setState({ anchorElT: event.currentTarget,roomListId1: projectRoomId });
  };

  handleRoomListPopup = (event: React.MouseEvent<HTMLElement>, projectRoomId: string) => {
    this.setState({ anchorElT1: event.currentTarget, roomListId: projectRoomId });
  };

  handleRoomListPopup1 = (event: React.MouseEvent<HTMLElement>, projectRoomId: string) => {
    this.setState({ anchorElT11: event.currentTarget, roomListId1: projectRoomId });
  };

  handleClose2 = ()=>{
    this.setState({anchorElT:null})
}

handleRoomClose = ()=>{
  this.setState({anchorElT1:null,roomListId:null})
  this.setState({anchorElT11:null,roomListId1:null})
}

formatDate = (date: Date | null): string => {
  if (!date) return "";
  return date.toLocaleDateString("en-US", {
    month: "short", 
    day: "numeric", 
    year: "numeric"
  });
};
  handleDateChange = (event:any)=>{
        if (event) {
          this.selectCalenderDate()
         this.setState({tempDate:event})
         this.setState({ startDateOpen: event[0] })
         this.setState({ endDateOpen: event[1] })
    }
  }

  isTileDisabled = ({ date, view }: { date: Date; view: string }): boolean => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date < today && view === "month";
  };
  
  getTileClassName = ({ date, view }: { date: Date; view: string }): string | null => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (date < today && view === "month") {
      return "disabled-date";
    }
    return null;
  };

   getShellsData = async() => {
    const pathname = window.location.pathname;
    const pathnameArr = pathname.split("/");
    const projectId = pathnameArr[pathnameArr.length-1]
    const token = await getStorageData("token") 
    const header = {
      "token":token,
      "Content-Type": configJSON.validationApiContentType,
    };
    const storedScopeId = localStorage.getItem("scopeId");
    const scopeId = storedScopeId ? JSON.parse(storedScopeId) : null;

    
    this.setState({loading:true})

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.shellsGetCallid = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_categories/categories?project_id=${projectId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  } 

  handleShellsResponse = (res:any)=>{
    let response = res.map((item:any)=> {
      return  {
        id: item.id,
        logo: item.attributes.icon_url,
        name: item.attributes.name,
        amount:item.attributes.count,
      }
    } )
    this.setState({shellsData:response, shellsSubstructures:response,
      filteredItems: response})
  }

  getRoomsData = async() => {
    const token = await getStorageData("token")
    const projectId = await getStorageData("project_id")
    const pathnameRooms = window.location.pathname;

    const header = {
      "token":token,
      "Content-Type": configJSON.validationApiContentType,
    };

    this.setState({loading:true})
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.roomsGetCallid = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories/rooms?project_id=${projectId}`
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );
   
    runEngine.sendMessage(requestMessage.id, requestMessage);
  } 
  
  handleRoomsResponse = (res: any) => {
    const seenNames = new Set();
    
    const uniqueArray = res.filter((value: any) => {
      const name = value.attributes.name;
      if (seenNames.has(name)) {
        return false; 
      }
      seenNames.add(name);
      return true;
    });
  
    let response = uniqueArray.map((item: any) => {
      return {
        id: item.id,
        logo: item.attributes.icon_url,
        name: item.attributes.name,
        amount: item.attributes.count,
      };
    });
  
    this.setState({
      roomsData: response,
      filteredItems:response,
      shellsSubstructures:response
    });
    this.addNewRoomSearchBoxData(response)
  };
  handleButton = () => {
    const { buttonId } = this.state;
  
    let newStages = [];
  
    if (buttonId === "button1") {
      newStages = this.state.getStagesData;
    }
    if (buttonId === "button2") {
      newStages = this.state.getRoomData;
    }
    if (buttonId === "button3") {
      newStages = [
        { room_name: "Labour", room_id: 4,id:6 },
        { room_name: "Material", room_id: 5,id:8 }
      ];
    }
  
    this.setState({ stages: newStages });
  };
  
 
  handleToggleButton = (object:object) => {
    let isBlank = false;
    _.forEach(object,(data:string)=>{
    if(data.includes("can't be blank")){
      isBlank = true
      return false;
    }
    })
    return isBlank;
  }
  handleOpenViewAndAddMemberDiload=()=>{
    const project_id = this.state.projectId
    const planId = this.state.planId
    console.log("project_id : ",project_id)
    console.log("plan_id : ",planId)
    this.setState({openAddMemberDialog:true})
  }
  handleCloseViewAndAddMemberDiload =()=>{
    this.setState({
      openAddMemberDialog:false
    })
  }

  handleMaterialPopUpClose =()=>{
    this.setState({
      materialPopUP:false,
      number:"00.00",
      number1:"00.00",
      anchorEl:null,
    })
  }

  handlelabourPopUPClose =()=>{
    this.setState({
      labourPopUP:false,
      anchorEl:null,
    })
  }
  handleCloseDialogAndNavigatetoBack=()=>{
    this.setState({openDialog:false,openDialogLeave:false,snackbarOpen:false})
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ProjectPortfolio"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);

  }

  handleHitAPIsFinalNext=async()=>{
    const name = await getStorageData('firstNameClient')
    const last = await getStorageData('lastClientName')
    const email = await getStorageData("emailOfTheClient")
    const token1 = await getStorageData("token")

   const header ={ 
    "Content-Type": configJSON.validationApiContentType,
    token: token1,
  };

  const payload ={
    data:{
      attributes:{
        first_name:name,
        last_name:last,
        email:email,
        company_name:"client",
        role_id:"client"
      }
    }
  }
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.apiHitClientEmailLogin = requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.inviteClientDatavalue}`
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(payload)
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.postAPiEndMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleNext = async() => {
    this.setState({nextButtonClicked:true})
    const path = window.location.pathname
    const ids=this.getIds(path)
    this.setState({activeStep:Number(this.state.activeStep)+1,addNewTaskPopup:false})
    this.handleStepStateApi(Number(this.state.activeStep)+1,ids.projectId);
    
    if (this.state.activeStep === 1) {
      storage.set('activeStep',Number(this.state.activeStep)+1)
       this.getRoomsData()
       this.createShellsApiCall()
    }

    if (this.state.activeStep === 2) {
      storage.set('activeStep',Number(this.state.activeStep)+1)
        this.createRoomsApiCall()
   }

    this.setBackBtnOverviewClick()

    if(this.state.activeStep === 3){
      storage.set('activeStep',Number(this.state.activeStep)+1)
      this.updateDimensionsApiCall()
      this.createRoomsTasklists()
      this.postAutoTaskList()
    }
  

    if(this.state.activeStep === 4){
      storage.set('activeStep',Number(this.state.activeStep)+1)
      this.handleHitAPIsFinalNext()
      const path = window.location.pathname
      const {planId,projectId} = this.extractIdsFromPath(path)
      const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "QuoteOverview"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), `${planId}/project_id/${projectId}`);
    this.send(message);
    }

  }

  handleCalendarOpen = (event: React.MouseEvent<HTMLElement>,projectRoomId:string) => {
    this.setState({ anchorElt3: event.currentTarget,tasksListId:projectRoomId});
  };

  handleCalendarClose = ()=>{
    this.setState({anchorElt3:null,tasksListId:null})
  }

  handleSpecificationOpen = ()=>{
       this.setState({specificationActive:!this.state.specificationActive})
  }

  handleSpeciChange = async (value: any) => {
    this.addTaskApiCallID = await this.addTaskApiCall({
      contentType: configJSON.getContentType,
      endPoint: `/bx_block_tasks/project_rooms_task_lists_tasks/${value.id}/add_task_to_tasklist?project_id=${this.state.projectId}&room_id=${this.state.roomId}&task_list_id=${this.state.taskListId}`,
      method: "POST"
    });
  }

  addTaskApiCall = async (ApiCallData: any) => {
    const { contentType, method, endPoint, body } = ApiCallData;
    const token = await getStorageData("token");
    const header = {
      'Content-Type': contentType,
      "token": token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    body &&  requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handleSpecificationDropdown = async (subData: any, selectedOption: any) => {
    this.setState((prevState) => ({
      selectedSpecifications: {
        ...prevState.selectedSpecifications,
        [subData.id]: selectedOption,
      }
    }))
    this.updateSpecApiCallId = await this.addTaskApiCall({
      contentType: configJSON.getContentType,
      endPoint: `/bx_block_tasks/task_list_tasks_specifications/${subData.specifications[0].id}/update_specification_in_task`,
      method: "PUT",
      body: {
        "specification_id": selectedOption?.id
      }
    });
  }
  handleTaskDropDown = async (subData: any, selectedOption: any) => {
    this.setState((prevState) => ({
      selectedTask: {
        ...prevState.selectedTask,
        [subData.id]: selectedOption,
      },
      selectedTaskId: {
        ...prevState.selectedTaskId,
        [subData.id]: selectedOption?.id
      }
    }));
    this.updateTaskApiCallId = await this.addTaskApiCall({
      contentType: configJSON.getContentType,
      endPoint: `bx_block_tasks/project_rooms_task_lists_tasks/${selectedOption?.id}/update_task_in_tasklist`,
      method: "PATCH",
      body:{
        "project_rooms_task_lists_task_id": subData.id
      }
    });
  };

  handleQuantityChange = (id: number, value: any) => {
    if (/^\d*$/.test(value)) {
    this.setState((prevState) => ({
      editedQuantities: { ...prevState.editedQuantities, [id]: value },
    }));
  }
  };

  updateQuantity = async ( subData: any) => {
    const updatedQuantity = this.state.editedQuantities[subData.id];
    let clientSupplied = this.state.selectedCheckboxes[subData.id];
    if (clientSupplied == undefined) {
      clientSupplied = subData.specifications[0]?.client_supplied
    }
    this.updateQuantityApiCallId = await this.addTaskApiCall({
      contentType: configJSON.getContentType,
      endPoint: `/bx_block_tasks/task_list_tasks_specifications/${subData.specifications[0].id}/update_quantity`,
      method: "PUT",
      body: 
      {
        "task_list_tasks_specifications": {
          "quantity": updatedQuantity,
          "client_supplied": clientSupplied 
        }
      }
    });
  }

  allowOnlyNumbers = (event: any) => {
    if (typeof event.preventDefault === 'function' && !/^\d$/.test(event.key) && event.key !== "Backspace" && event.key !== "Delete") {
      event.preventDefault();
    }
  };

handleSpeciChange3 =(event:any,value:any)=>{
  this.setState({measurementUnit:value})
  
}

  handleClientSuppliedCheckboxChange = (subDataId: number, initialValue: boolean,subData: any) => {
    this.setState((prevState) => {
      const updatedSelection = { ...prevState.selectedCheckboxes };
      const currentValue = updatedSelection[subDataId] !== undefined
        ? updatedSelection[subDataId]
        : initialValue;

      updatedSelection[subDataId] = !currentValue;
      return { selectedCheckboxes: updatedSelection };
    }, () => {
      this.updateQuantity(subData)
    });
  };


  handleSpecificationClose = ()=>{
    this.setState({specificationActive:false})
  }

  handleCheckboxChange = (option: string, subData: any) => {
    this.setState((prevState) => {
      const updatedSelection = { ...prevState.selectedSpecifications };
      updatedSelection[subData.id] = prevState.dropDownSpecification.find(
        (spec) => spec.specification_name === option
      ) || "";

      return {
        selectedOptions: [option],
        selectedSpecifications: updatedSelection,
      };
    }, async () => {
      const selectedOption = this.state.dropDownSpecification.find(
        (spec) => spec.specification_name === option
      );
      if (selectedOption) {
        await this.handleSpecificationDropdown(subData, selectedOption);
      }
    }
    );
  };

  handleAutocompleteChange = (setFieldValue:any, field:any, value:any) => {
    setFieldValue(field, value); 
  };

  createOwnTask = async (values:any) => {
    const token = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      "token":token
    };

  const payload = {    
      "task": {
        "task_name": values.taskName,
        "specification_name": values.specifications,
        "quantity":  Number(values.Qty),
        "measurement_unit": Number(values.measurementUnit),
        "labour_cost": Number(values.labourCostUnit),
        "material_cost": Number(values.materialCostUnit),
        "project_id": this.state.projectId,
        "room_id": this.state.roomId,
        "task_list_id": this.state.taskListId1
      }
}

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createOwnTaskId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tasks/task_list_tasks_specifications/create_your_own_task`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTaskList = async() => {
    const token = await getStorageData("token")
    this.setState({loading:true})
    const header = {
      "token":token,
      "Content-Type": configJSON.validationApiContentType,
    };

    this.setState({loader:true})
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTaskListId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_tasks/task_lists/"
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );
   
    runEngine.sendMessage(requestMessage.id, requestMessage);
  } 

  postAutoTaskList = async() => {
    const token = await getStorageData("token")
    this.setState({loading:true})
    const header = {
      "token":token,
      "Content-Type": configJSON.validationApiContentType,
    };

    this.setState({loader:true})
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postAutoTaskId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tasks/projects/${this.state.projectId}/project_rooms_task_lists`
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
   
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handleTaskListResponse = (res:any)=>{
    this.setState({loading:false,taskList:res})  
  }

  getTaskList1 = async() => {
    const token = await getStorageData("token")
    this.setState({loading:true})
    const header = {
      "token":token,
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTaskListId1 = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTaskList}${this.state.projectId}`
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );
   
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  formatDateDate(dateString:any) {
    const date = new Date(dateString);
    return `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`;
  }
 
  handleGetAddedMembersList=()=>{const header = {
        "Content-Type": configJSON.getContentType,token: this.state.token,
      };
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.apiGetAddedMembersListCallId = requestMessage.messageId;
      
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getAddedMembersListAPIEndPoint}?project_id=${this.state.projectId}`);
      
      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header));
      
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPiEndMethod);
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getAddedMembersListResponse=(responseJson:any)=>{
    this.setState({
      getAllAddedMemeber:responseJson
    })
   
  }

  selectCalenderDate = async()=>{
    const token = await getStorageData("token")    
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      
      this.calenderDate = requestMessage.messageId;
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_profile/project_rooms/${this.state.tasksListId}/update_dates`
      );
  
      
      const body={
          "project_room": {
            "start_date": this.formatDateDate(this.state.startDateOpen),
             "end_date": this.formatDateDate(this.state.endDateOpen)
        }
      }
      
       requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PUT"
      );
      
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

   extractIdsFromPath = (path:string)=>{
    const pathSegments = path.split("/");
    const planId = parseInt(pathSegments[2]);
    const projectId = parseInt(pathSegments[4]);
    return {planId,projectId}
  }

  getBackBtnQuoteOverviewClick = async ()=>{
    const backBtnClicked = await getStorageData("backBtnQuoteOverviewClick");
    const parseItemsdata = JSON.parse(backBtnClicked);


    if(parseItemsdata){
      this.setState({
        activeStep:4,
        enableNextBtn:true,
      })
     
    }


  }

  setBackBtnOverviewClick = async ()=>{
    this.setState({
      enableNextBtn:false
    })
    await setStorageData("backBtnQuoteOverviewClick",JSON.stringify(false))
    
  }




  addNewRoomSearchBoxData   = (roomsData:RoomsDataItem[])=>{
    const searchRoomsData = roomsData.map((data:RoomsDataItem)=>{
      return {
        ...data,
        width: "0.00",
        height:"0.00",
        depth:"0.00",
        area:"0.00",
        perimeter:"0.00",
        checked:false
      }
    })

    this.setState({
      searchRoomsData,
      originalRoomsData:[...searchRoomsData]
    })
}


  toggleAddNewRoomPopup = ()=>{
    this.setState({
      addNewTaskPopup:!this.state.addNewTaskPopup
    })
  }

  toggleAddNewRoomPopup1 = ()=>{
    this.setState({
      openDialog1:!this.state.openDialog1
    })
  }

  getAddTaskList = async ()=>{
    const tokenRequired = await getStorageData("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token:tokenRequired,
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.getAddTaskListData = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_tasks/task_lists`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  roomFilterData = async () => {
    const tokenRequired = await getStorageData("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: tokenRequired,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.roomFilterDataApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/project_rooms/room_list/${this.state.projectId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  stagesFilterData = async ()=>{
    const tokenRequired = await getStorageData("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token:tokenRequired,
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.roomFilterDataApi1 = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tasks/project_rooms_task_lists/project_rooms_task_lists/${this.state.projectId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  addNewRoomCancelBtn = ()=>{
    const searchRoomsData = this.state.searchRoomsData.map((data)=>{
      return {
        ...data,
        checked:false
      }
    })
    this.setState({
      addNewTaskPopup:false,
      openDialog1:false,
      searchRoomsData,
      selectedAddRoomPopup:[]

    })
  }

  materailUiList = async ()=>{
    const tokenRequired = await getStorageData("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token:tokenRequired,
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.materailUiApiId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tasks/task_list_tasks_specifications?project_id=${this.state.projectId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  addNewRoomCancelBtn1 = ()=>{
    const searchRoomsDat1 = this.state.searchRoomsDat1.map((data)=>{
      return {
        ...data,
        checked:false
      }
    })
    this.setState({
      openDialog1:false,
      searchRoomsDat1,
      selectedAddRoomPopup:[],
      searchByRoomText1:""

    })
  }

  addNewRoomAddBtn = ()=>{
    this.updateDimensionsApiCall()
    const searchRoomsDataNew = this.state.searchRoomsData.map((dataNew)=>{
      return {
        ...dataNew,
        checked:false
      }
    })
    const data = [...this.state.selectedAddRoomPopup];
    const roomsAllData = [...this.state.roomsCalculatedData,...data]
    const uniqueRooms = new Set();
    const roomsCalculatedData = roomsAllData.filter((room)=>{
      const isDuplicate = uniqueRooms.has(room.id)
      uniqueRooms.add(room.id)
      return !isDuplicate
    })
   
    this.setState({
      addNewTaskPopup:false,
      selectedAddRoomPopup:[],
      searchRoomsData:searchRoomsDataNew,
      roomsCalculatedData
    })
    this.createShellsApiCall1()
  }

  addNewRoomAddBtn1 = (roomId:string|number)=>{
    const searchRoomsDataNew = this.state.searchRoomsDat1.map((dataNew)=>{
      return {
        ...dataNew,
        checked:false
      }
    })
    const data = [...this.state.selectedAddRoomPopup];
    const roomsAllData = [...this.state.roomsCalculatedData,...data]
    const uniqueRooms = new Set();
    const roomsCalculatedData = roomsAllData.filter((room)=>{
      const isDuplicate = uniqueRooms.has(room.id)
      uniqueRooms.add(room.id)
      return !isDuplicate
    })
   
    this.setState({
      openDialog1:false,
      selectedAddRoomPopup:[],
      searchRoomsDat1:searchRoomsDataNew,
      roomsCalculatedData
    })
    this.createShellsApiCall2(roomId)
  }


  handleCheckedRooms = (roomsId:string,checked:boolean)=>{

    const updatedSearchRoomsData = this.state.searchRoomsData.map((roomData)=>{
      if(roomData.id === roomsId){
        return {
          ...roomData,
          checked:!checked
        }
      }

      return roomData
    })

    const selectedRooms = updatedSearchRoomsData.filter((room)=>room.checked === true)
    const roomsPayload = selectedRooms.map((room) => ({
      room_id: parseInt(room.id, 10),
    }));
    
    this.setState({
      searchRoomsData:updatedSearchRoomsData,
      selectedAddRoomPopup:selectedRooms,
      payloadRoomsData: roomsPayload

    })


  }

  handleCheckedRooms1 = (roomsId:string,checked:boolean)=>{
    const updatedSearchRoomsData = this.state.searchRoomsDat1.map((roomData)=>{
      if(roomData.id === roomsId){
        return {
          ...roomData,
          checked:!checked
        }
      }

      return roomData
    })

    const selectedRooms = updatedSearchRoomsData.filter((room)=>room.checked === true)
    const roomsPayload = selectedRooms.map((room) => ({
      project_rooms_task_list_id: parseInt(room.id, 10),
    }));
    
    this.setState({
      searchRoomsDat1:updatedSearchRoomsData,
      selectedAddRoomPopup:selectedRooms,
      payloadRoomsData1: roomsPayload

    })
  }


  handleSearchByRoomText = (text:string)=>{
    const searchText = text?.toLowerCase();
    const filteredSearchRoomsData = searchText ? this.state.originalRoomsData.filter((item)=>{
      return item.name.toLowerCase().includes(searchText)
    }) : this.state.originalRoomsData


    this.setState({
      searchByRoomText:text,
      searchRoomsData:filteredSearchRoomsData
    })
  }

  handleSearchByRoomText1 = (text:string)=>{
    const searchText = text?.toLowerCase();
    const filteredSearchRoomsData = searchText ? this.state.dropDownSpecification12.filter((item)=>{
      return item.attributes.name.toLowerCase().includes(searchText)
    }) : this.state.dropDownSpecification12


    this.setState({
      searchByRoomText1:text,
      searchRoomsDat1:filteredSearchRoomsData
    })
  }

  handleOpenDialog1 = () => {
    this.setState({ openDialog1: true })
  }
handleMeasurementFields = (id:string,field:string,value:string)=>{

  const sanitizedValue = value
  .replace(/[^0-9.]/g, '') 
  .replace(/^0+(?!\.)/, '')
  .match(/^\d*(\.\d{0,2})?/)?.[0] || ''; 

  const updatedRoomsData = this.state.showRoomListData.map((room)=>{
    if(room.id === id){
      return {
        ...room,
        [field]:sanitizedValue
      }
    }
    return room;
  })

  
  this.setState({ showRoomListData: updatedRoomsData }, () => {
    this.calculateAreaAndPerimeterOfRoom(id);
  });
  
}

 handleOnInput = (e: React.FormEvent<HTMLInputElement>)=>{
    const inputElement = e.target as HTMLInputElement;
    if (inputElement.value.includes("-")) {
      inputElement.value = inputElement.value.replace("-", "");
    }
  }



calculateAreaAndPerimeterOfRoom = (id:string)=>{
  const showRoomListData = this.state.showRoomListData.map((room:any)=>{
    if(room.id === id){
      return {
        ...room,
        area:(Number(room.width) * Number(room.depth)).toFixed(2),
        perimeter: (Number(room.width) + Number(room.depth)).toFixed(2)
      }
    }
    return room;
  })

  this.setState({
    showRoomListData
  },()=>{
    this.calculateTotalAreaAndPerimeter()
  })
  
}

calculateTotalAreaAndPerimeter = ()=>{
  const totalArea = this.state.showRoomListData.reduce((sum, item) => {
    const area = parseFloat(item.area); 
    return sum + (isNaN(area) ? 0 : area); 
  }, 0);

  const totalPerimeter = this.state.showRoomListData.reduce((sum, item) => {
    const perimeter = parseFloat(item.perimeter); 
    return sum + (isNaN(perimeter) ? 0 : perimeter); 
  }, 0);


  const dimensionRoomsArray = this.state.showRoomListData.map(({id,...props})=>{
    const filteredProperties = Object.entries(props).reduce((acc:any,[key,value])=>{
      if(["width", "height", "depth", "area", "perimeter"].includes(key) && parseFloat(value)>0){
        acc[key] = Number(value);
      }
      return acc;
    },{})
    return Object.keys(filteredProperties).length > 0 ? { id:Number(id), ...filteredProperties } : null;
  }).filter(Boolean)


  this.setState({
    totalArea,
    totalPerimeter,
    dimensionRoomsArray
  })
}


selectPropertyTypesAndScopesApiCall = ()=>{
  const {projectId,selectedType,multipleScopesSelected} = this.state;
  const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.selectPropertyTypesApi = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.multipleScopesSelectEndpoint}${projectId}`
    );

    const projectObj = multipleScopesSelected.length > 0 ? {"scope_category_ids": multipleScopesSelected} : {"property_type_id": selectedType}
    
    const body={
        "project": projectObj
    }
    
     requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSelectPropertyTypesResponse = (responseJson:any)=>{
      this.setState({selectPropertyTypeResponse:responseJson.data})
      this.state.multipleScopesSelected.length > 0 ? this.navigationToShellsFromScopes() : this.navigateToProjectScope()
  }

  getMultipleSelectScopeIds = ()=>{
    const multipleScopesSelected = this.state.scopeCategories.filter(scope=>scope.selected).map(item=>item.id)
    this.setState({multipleScopesSelected})
    localStorage.setItem("multipleScopeIds",JSON.stringify(multipleScopesSelected))
  }


  createShellsWithCount = ()=>{
    const shellsWithCount = this.state.shellsSubstructures.filter((item)=>Number(item.amount) > 0).map(newItem=>({
      category_id: Number(newItem.id),
      count:Number(newItem.amount)
    }))

    this.setState({shellsWithCount})

  }


  getProjectIdFromUrl = ()=>{
  const path = window.location.pathname;
  const pathArr = path.split("/");
  const projectId = pathArr[pathArr.length-1]
  return projectId
  }

  createShellsApiCall = async ()=>{
    const tokenRequired = await getStorageData("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token:tokenRequired,
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.createShellsApiCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createShellsEndPoint}`
    );

   const projectId = this.getProjectIdFromUrl()
    
    const body={
      "project_id": projectId,
      "project_shell": {
          "shells": this.state.shellsWithCount
      }
      
    }
    
     requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  createShellsApiCall1 = async ()=>{
    const tokenRequired = await getStorageData("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token:tokenRequired,
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.addRoomApiCall = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/project_rooms/add_room`
    );

   const projectId = this.getProjectIdFromUrl()
    
    const body={
      "project_id": projectId,
      "project_room": {
        "rooms": this.state.payloadRoomsData
      }
    }
    
    
     requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handleClearAll = () => {
    const { buttonId, selectedItems } = this.state;  
    this.setState({
      selectedItems: {
        ...selectedItems,
        [buttonId]: new Set(),
      }
    }, () => {
      const hasSelectedItems = this.state.selectedItems[buttonId]?.size > 0;
      if (hasSelectedItems) {
        this.applyFilter();
      } else {
        this.getTaskList1();
      }
    });
  };
  
  
  applyFilter = async () => {
    const tokenRequired = await getStorageData("token");
    const projectId = this.getProjectIdFromUrl();

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: tokenRequired,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.applyFilterApiCall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const { selectedItems } = this.state;
    const selectedRoomIds: [number, { id: number; room_id: number }][] = Array.from(selectedItems["button2"] || []);
    const roomIds = selectedRoomIds.map(([_, data]) => data.room_id);

    const selectedTakIds: [number, { id: number; room_id: number }][] = Array.from(selectedItems["button1"] || []);
    const taskListIds = selectedTakIds.map(([_, data]) => data.room_id);
    let queryParams = `room_ids[]=${roomIds.join('&room_ids[]=')}&task_list_ids[]=${taskListIds.join('&task_list_ids[]=')}`;
    const url = `bx_block_profile/projects/${projectId}/project_rooms/filter?${queryParams}`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createShellsApiCall2 = async (roomId:string|number)=>{
    const tokenRequired = await getStorageData("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token:tokenRequired,
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.addtaskListApiCall = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tasks/project_rooms_task_lists/add_task_list_to_room`
      
    );

   const projectId = this.getProjectIdFromUrl()

    const body={
      "project_id": projectId,
      "room_id": roomId,
      "task_lists": this.state.payloadRoomsData1
    }
    
    
     requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handeleCreateShellsResponse = (response:CreateProjectShellResponse)=>{
    this.setState({
      createProjectShellsResponse:response
    })
  }

  createRoomsWithCount = ()=>{
    const roomsWithCount = this.state.shellsSubstructures.filter((room)=>Number(room.amount) > 0).map((item)=>({
      room_id: Number(item.id),
      count : Number(item.amount)
    }))

    this.setState({roomsWithCount})

  }

  createRoomsApiCall = async ()=>{
    const tokenReq = await getStorageData("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token:tokenReq,
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.createRoomsApiCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createRoomsEndPoint}`
    );

   const projectId = this.getProjectIdFromUrl()
    
    const body={
      "project_id": projectId,
      "project_room": {
          "rooms": this.state.roomsWithCount
      }
      
    }
    
     requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleCreateRoomsResponse = (response:any)=>{
      this.setState({createRoomsResponse:response})
  }

  getListOfRoomsStep4 = async ()=>{
    const tokenRequired = await getStorageData("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token:tokenRequired,
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.getRoomsListApiCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const projectId = this.getProjectIdFromUrl()
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRoomsListEndPoint}${projectId}?page=${this.state.pageNumber}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getRooomListStep4Response = (responseJson:GetRoomListStep4Interface)=>{
    const showMeasurement = (value:any)=>{
     return value ? String(value.toFixed(2)) : ''
    }

   const roomNamesWithCountArr = responseJson?.data?.data && Array.isArray(responseJson?.data?.data) && responseJson?.data?.data.length > 0 ? this.transformRoomNameWithNumber(responseJson.data?.data) : []
   const showRoomListData =  roomNamesWithCountArr.map((room:any)=>{
    return {
      id: String(room.attributes.id),
      room_id: String(room.attributes.room_id),
      room_name: room.newName,
      width:showMeasurement(room.attributes.width),
      height:showMeasurement(room.attributes.height),
      depth:showMeasurement(room.attributes.depth),
      area:showMeasurement(room.attributes.area),
      perimeter:showMeasurement(room.attributes.perimeter),
    }
   })


   this.setState({
    showRoomListData
   })
   this.calculateTotalAreaAndPerimeter()
  }

  handleOpenDeleteDialog = ()=>{
    this.setState({openDeleteDialog:true})
  }

  duplicateRoomApiCall1 = async ()=>{
    const token = await getStorageData("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.duplicateRoomApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_profile/project_rooms/${this.state.roomListId}/duplicate_project_room`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getDeleteMessage = () => {
    const { activeStep, anchorElT, anchorElT11 } = this.state;
  
    if (activeStep === 4 && anchorElT11) {
      return "Are you sure you want to delete this taskList?";
    } else if (anchorElT) {
      return "Are you sure you want to delete this task?";
    } else if (activeStep === 3 || activeStep === 4) {
      return "Are you sure you want to delete this room?";
    }
    return "";
  };
  

  duplicateTaskApiCall = async ()=>{
    const token = await getStorageData("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.duplicateTaskApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tasks/project_rooms_task_lists_tasks/${this.state.roomListId1}/duplicate_project_rooms_task_lists_task`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  duplicateRoomApiCall2 = async ()=>{
    const token = await getStorageData("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.duplicateRoomApiId1 = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tasks/projects/duplicate_project_tasklist/${this.state.roomListId1}`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleCloseDeleteDialog = ()=>{
    this.setState({openDeleteDialog:false})
  }

  gETStepDropDown1 = async ()=>{
    const tokenRequired = await getStorageData("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token:tokenRequired,
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.gETStepDropDown = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tasks/tasks/admin_tasks`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
 

  deleteRoomApiCall  = async () =>  {
    const token = await getStorageData("token")
    const header = {
      "Content-Type": "application/json",
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.deleteRoomApiCallId = requestMessage.messageId;
  
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteRoomEndPoint}${this.state.deleteRoomId}`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  deleteRoomApi  = async () =>  {
    const token = await getStorageData("token")
    const header = {
      "Content-Type": "application/json",
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.deleteRoomApiId = requestMessage.messageId;

  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteRoomEndPoint}${this.state.roomListId}`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  deleteRoomApi1  = async () =>  {
    const token = await getStorageData("token")
    const header = {
      "Content-Type": "application/json",
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.deleteRoomApiId1 = requestMessage.messageId;

  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tasks/projects/delete_project_tasklist/${this.state.roomListId1}`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteRoomApi2  = async () =>  {
    const token = await getStorageData("token")
    const header = {
      "Content-Type": "application/json",
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.deleteRoomTaskApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tasks/project_rooms_task_lists_tasks/${this.state.roomListId1}`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
   
  handleDeleteClick = () => {  
    if (this.state.activeStep === 3) {
    this.deleteRoomApiCall();
    this.setState({deleteUpadte:true})
    this.updateDimensionsApiCall();
    } 
    else if (this.state.activeStep === 4) {
      if (this.state.anchorElT11 !== null && this.state.anchorElT11 !== undefined) {
        this.deleteRoomApi1();
      }
      else if (this.state.anchorElT !== null && this.state.anchorElT !== undefined) {
        this.deleteRoomApi2();
      } else {
        this.deleteRoomApi();
      }
    }
  };
  
  getSepcificationDrop1 = async (subData: any)=>{
    let selectedTaskId = this.state.selectedTaskId[subData.id]
    if (selectedTaskId == undefined) {
      selectedTaskId = subData.task_id
    }
    const tokenRequired = await getStorageData("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token:tokenRequired,
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.getSepcificationDrop = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tasks/tasks/${selectedTaskId}/specifications`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  updateDimensionsApiCall = async ()=>{
    const tokenRequired = await getStorageData("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token:tokenRequired,
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.updateDimensionsApiCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateDimensionsEndPoint}`
    );

    
    const body={
      "project_room": {
          "rooms":this.state.dimensionRoomsArray,
      }
  }
    
     requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  createRoomsTasklists = async ()=>{
    const token = await getStorageData("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.createTasklistsApiCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createProjectRoomsTasklists}`
    );

    
    const body={
      "task_lists": [],
      "navigation_draft": "project rooms task list"
    }
    
     requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  duplicateRoomApiCall = async ()=>{
    const token = await getStorageData("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.duplicateRoomApiCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_profile/project_rooms/${this.state.deleteRoomId}/duplicate_project_room`
    );

  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  transformRoomNameWithNumber = (inputArray:ProjectRoom[])=>{
    const roomNameWithCount:any = {};

    return inputArray.map((item)=>{      
      const roomName = item.attributes.room_name;
      const roomId = item.attributes.id;

      roomNameWithCount[roomName] = (roomNameWithCount[roomName] || 0) + 1;

      const newName = roomNameWithCount[roomName] === 1 ? roomName : `${roomName} (${roomNameWithCount[roomName] - 1})`;
      return {roomId,newName,...item}
    })
  }

  marginOfSearchBar = ()=>{
        const searchBar =  this.state.activeStep < 4 ? "15px" : "0px";
        return searchBar;
  }
  handleChangeEdit = (val: string) => {
    this.setState({mesurmentQty:val});
  }
  handleAreaPeremiterDuplicate=()=>{
  this.setState({ nextButtonClicked: false });
  this.updateDimensionsApiCall();
 }

  initiallySelected = (subData: any, option: any) => {
    return subData.specifications[0]?.specification_name === option.specification_name;
  };

  userSelectedSpecification = (option: any) => {
    return this.state.selectedOptions.includes(option.specification_name);
  };
  checkInitialSelection = (option: any, subData: any) => {
    return this.userSelectedSpecification(option) ||
      (!this.state.selectedOptions[subData.id] && this.initiallySelected(subData, option))
  };

  handleAddTaskDropdownToggle = (id: number) => {
    this.setState((prevState) => ({
      openAddTaskDropdownId: prevState.openAddTaskDropdownId === id ? null : id,
    }));
  };
  handleAddSpecDropdownToggle = (id: number) => {
    this.setState((prevState) => ({
      openAddSpecDropdownId: prevState.openAddSpecDropdownId === id ? null : id,
    }));
  };
} 

                                                                                                                                                                                                                                    
// Customizable Area End
